<template>
  <section class="primer-chatbot">
    <template v-if="completed == false">
      <div class="text-center my-5">
        <h1 class="primer-chatbot__title">
          ¡Crea tu <span class="destacado">primer</span> Chatbot!
        </h1>
        <BulletSteps :step="step" />
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4">
          <div class="card">
            <form role="form" class="formulario">
              <div class="label-box mb-3">
                <label class="mb-0">Nombre del Chatbot</label>
                <p class="text_sm text-right mb-1">{{ chatbot.bot_name.length }} / 15 carácteres.</p>
              </div>
              <input
                alternative
                required
                type="text"
                v-model="chatbot.bot_name"
                name="bot_name"
                maxlength="15"
                placeholder="Nombre"
                class="mb-3"
              />
              <p class="text_error" v-if="error.name">Ingresa un nombre</p>
              <label>Logo del Chatbot</label>
              <input type="file" class="mb-3" @change="processFile($event)" name="bot_logo_url" />
              <label
                :class="{
                  'toggle_card w-100 my-3': true,
                  active: chatbot.has_skill_base
                }"
                for="skill-base"
              >
                <div class="label-box">
                  <p :class="{'mb-0':true, grey: !chatbot.has_skill_base}">¿Eres E-commerce?</p>
                  <div class="d-flex justify-content-center align-items-center">
                    <span
                      :class="{
                        'mr-3 mb-0': true,
                        link: chatbot.has_skill_base,
                        grey: !chatbot.has_skill_base
                      }"
                    >{{ chatbot.has_skill_base ? "Sí" : "No" }}</span>
                    <label class="switch">
                      <input type="checkbox" v-model="chatbot.has_skill_base" id="skill-base" checked>
                      <span class="slider" />
                    </label>
                  </div>
                </div>
              </label>
              <!-- <p class="text_error" v-if="error.chatbot">Ingresa un nombre</p> -->
              <div class="row">
                <div class="contenedor__color pl-0">
                  <label>Color primario</label>
                  <div class="temas__item-color">
                    <input
                      type="color"
                      v-model="chatbot.bot_title_bar_bg_color"
                      name="bot_title_bar_bg_color"
                    />
                  </div>
                </div>
                <div class="contenedor__color">
                  <label>Color secundario</label>
                  <div class="temas__item-color">
                    <input type="color" v-model="chatbot.bot_title_color" name="bot_title_color" />
                  </div>
                </div>
              </div>
              <button
                :disabled="loading"
                type="primary"
                class="btn btn-base second my-4"
                @click.prevent="postChatBot"
              >
                Crear chatbot
              </button>
            </form>
          </div>
        </div>
        <!-- Chatbot -->
        <div class="col-lg-4">
          <div class="card">
            <Chatbot
              class="mt-1"
              :chatbot__trama="chatbot.trama_url"
              :chatbot__logo="chatbot.bot_logo_url"
              :chatbot__nombre="chatbot.bot_name"
              :chatbot__color__principal="chatbot.bot_title_bar_bg_color"
              :chatbot__color__secundario="chatbot.bot_title_color"
              :chatbot__bot__color__fondo="chatbot.bot_box_bg_color"
              :chatbot__bot__color__letra="chatbot.bot_box_font_color"
              :chatbot__user__color__fondo="chatbot.user_box_bg_color"
              :chatbot__user__color__letra="chatbot.user_box_font_color"
            />
          </div>
        </div>
        <br />
        <br />
      </div>
    </template>
    <template v-else>
      <div class="chatbot-done">
        <img class="iso-logo" src="/img/icons/dashboard/0-user-profile.svg" alt="logo" />
        <h1>¡Chatbot creado!</h1>
        <p>Ahora puedes ingresar a nuestra plataforma.</p>
        <br />
        <router-link target="_blank" to="/dashboard" class="btn btn-base second">
          Ingresar
        </router-link>
      </div>
      <br />
    </template>
  </section>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import dashboard_api from "@/dashboard_api";
import Chatbot from "@/components/Chatbot.vue";
import BulletSteps from "./BulletSteps";
import chatbot_api from "@/chatbot_api.js";

export default {
  name: "firstbot",
  props: ["step", "web"],
  components: {
    Chatbot,
    BulletSteps
  },
  data() {
    return {
      loading: false,
      completed: false,
      chatbot: {
        bot_logo_url: "", // Imagen bot
        bot_name: "Chatbot", // Nombre del bot
        user_box_bg_color: "#ffffff",
        user_box_font_color: "#525252",
        bot_box_bg_color: "#D8EEFF",
        bot_box_font_color: "#525252",
        bot_title_bar_bg_color: "#181818",
        bot_title_color: "#ffffff",
        trama_url: "",
        web: "",
        business_types_id: 0,
        time_zone: "",
        industries: "",
        has_skill_base: true,
        init_conver_mobile: false
      },
      my_register: {},
      error: {
        name: false,
        chatbot: ""
      },
      bots: [],
      image: '',
      business_types: [
        {
          label: "Tienda online",
          value: "Ecommerce",
        },
        // {
        //   label: "Inmobiliaria",
        //   value: "Real Estate",
        // },
        {
          label: "Otro",
          value: "Otro",
        },
        // {
        //   label: "Seguros",
        //   value: "Insurtech",
        // },
      ],
      setSkillbase: false
    };
  },

  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    this.chatbot.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.chatbot.web = this.web;
    this.my_register = this.registerData;
    this.getBusinessType();
  },

  computed: {
    ...mapState([
      "user",
      "registerData",
      "active_view",
      "platform_name",
      "platform_shop",
      "platform_access"
    ]),
  },
  methods: {
    ...mapMutations(["setRegisterProcess", "setActiveView"]),

    async getBots() {
      const self = this;
      const owner = self.active_view === "my_bots";

      self.bots = (await dashboard_api.get(`/bot/list/${self.user.id}?owner=true`)).data;

      self.bots.sort((a, b) => (a.activated > b.activated ? -1 : 1));

      self.$store.commit("setOwnerBots", self.bots);
    },

    async saveForSkillBase() {
      try {
        await this.$store.dispatch('dtClientSkillsModule/skillCreate', {
          bot_id: this.selected_bot.id,
          client_name: `${this.selected_bot.id}-PROD`,
          template: 'TEST001',
        });
        let uploadSkill = await this.$store.dispatch(
          'dtClientSkillsModule/skillUploadLLM',
          {
            bot_id: this.selected_bot.id,
          },
        );
        let assistantId = uploadSkill.payload.assistant_id;
        let inverseAssistantId = reverseString(assistantId);
        let allBusinessTypes = await this.$store.dispatch(
          'dtClientBusinessTypeModule/businessTypesListAll',
        );
        allBusinessTypes = allBusinessTypes.map(bot => bot.assistant_id);
        if (!allBusinessTypes.includes(inverseAssistantId)) {
          let createBusinessTypeId = await this.$store.dispatch(
            'dtClientBusinessTypeModule/businessTypeCreate',
            {
              name: `${this.selected_bot.id}-PROD`,
              show: false,
              product_type: 'chatbot',
              has_persistence: true,
              icon: '',
              activated: true,
              custom: true,
              api_key: '',
              assistant_id: assistantId,
              skill_id: '',
            },
          );
          let newBusinessTypeId = createBusinessTypeId.id;
          console.log('createdbusinessTypeId: ', newBusinessTypeId);
          await businessTypes.updateBusinessType(this.selected_bot.id, {
            chatbot: {
              id: this.selected_bot.id,
              business_types_id: newBusinessTypeId,
            },
          });
        }
        this.loadingComplete = false; // Ocultar indicador de carga botón
        this.$swal({
          icon: 'success',
          title: 'Chatbot Actualizado',
          timer: 1000,
          showConfirmButton: false,
        });
      } catch (error) {
        console.log("error->", error);
      }
    },

    async getBusinessType() {
      // this.chatbot.business_types_id = (await dashboard_api.get(`/business_types/by_name/${this.selected_business}`)).data.id;
      this.chatbot.business_types_id = (await dashboard_api.get(`/business_types/by_name/Ecommerce`)).data.id;
    },

    processFile(event) {
      const self = this;
      const reader = new FileReader();
      self.image = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function () {
        self.chatbot.bot_logo_url = reader.result;
      };
      reader.onerror = function (error) {};
    },

    formDataBody() {
      const data = new FormData();

      const {
        bot_name,
        bot_title_bar_bg_color,
        business_types_id,
        bot_title_color,
        has_skill_base,
        time_zone,
        web,
        init_conver_mobile
      } = this.chatbot;

      data.append("chatbot[bot_name]", bot_name);
      data.append("chatbot[user_box_bg_color]", "#ffffff");
      data.append("chatbot[user_box_font_color]", "#525252");
      data.append("chatbot[bot_box_bg_color]", "#D8EEFF");
      data.append("chatbot[bot_box_font_color]", "#525252");
      data.append("chatbot[bot_title_bar_bg_color]", bot_title_bar_bg_color);
      data.append("chatbot[bot_title_color]", bot_title_color);
      data.append("chatbot[business_types_id]", business_types_id);
      data.append("chatbot[trama_url]", "");
      data.append("chatbot[has_skill_base]", has_skill_base);
      data.append("chatbot[web]", web);
      data.append("activated", true);
      data.append("chatbot[time_zone]", time_zone);
      data.append("chatbot[init_conver_mobile]", init_conver_mobile);
      if (this.image) data.append("chatbot[bot_avatar]", this.image);

      return data;
    },

    postChatBot() {
      const self = this;
      self.error.name = !self.chatbot.bot_name;

      console.log("Nuevo chatbot->", self.chatbot);

      if (!self.error.name && !self.error.chatbot) {
        self.loading = true;

        if (self.chatbot.bot_logo_url == "/img/brand/0-default.svg") {
          self.chatbot.bot_logo_url = null;
        }

        self.$swal({
          icon: "info",
          title: "Estamos creando su chatbot...",
          showConfirmButton: false
        });
        self.$swal.showLoading();

        // if (self.platform_name == "shopify") {
        //   self.chatbot.web = self.$route.query.shop_domain;
        // } else {
        //   self.chatbot.web = self.user.url;
        // }
        const formData = self.formDataBody();

        dashboard_api
          .post("/bot", formData)
          .then(bot => {
            self.loading = false;
            self.my_register.bot = bot.data.id;
            self.my_register.botName = bot.data.bot_name;
            self.my_register.botToken = bot.data.token;
            self.my_register.step = 3;
            self.setRegisterProcess(self.my_register); // Actualizo los valores para el registro
            self.getBots(); // Cargo el bot en Store

            // self.$swal({
            //   icon: "success",
            //   title: "¡Chatbot creado!",
            //   timer: 1000,
            //   showConfirmButton: false
            // });

            // self.$router.push({
            //   query: {
            //     name: "register-planes",
            //     shop_domain: self.platform_shop,
            //     token_access: self.platform_access
            //   }
            // });
            // self.$emit("nextStep"); // Voy al siguiente paso

            // Acá va lo nuevo
            let plan = "plan profesional";
            dashboard_api
              .get(`/base_plans/with_channel_by_name/${plan}`)
              .then(withChannelByNameResponse => {
                let data = withChannelByNameResponse.data;

                dashboard_api
                  .post("/admissible_plans", {
                    base_plan_id: data.plan.id,
                    channels: [data.channel.id],
                    extra_licences: 0,
                    price: data.plan.price,
                    period: "monthly"
                  })
                  .then(admissiblePlansResponse => {
                    // let daysLeft = self.platform_name == 'shopify' ? 14 : 7;
                    let daysLeft = 7;

                    dashboard_api
                      .post("/client_subscriptions", {
                        bot_id: bot.data.id,
                        user_id: self.user.id,
                        admissible_plan_id: admissiblePlansResponse.data.id,
                        status: true,
                        days_left: daysLeft,
                        test_period: true
                      })
                      .then(() => {
                        self.ultimoPaso();
                        if (self.platform_name == "jumpseller") {
                          self.completed = true;
                          console.log("access token creado->", self.$route.query.token_access);
                        // } else if (self.platform_name == "shopify") {
                        //   self.$router.push({
                        //     name: "dashboard",
                        //     query: {
                        //       shop: self.$route.query.shop,
                        //       access_token: self.$route.query.access_token,
                        //       token_access: self.$route.query.token_access,
                        //       platform: self.platform_name,
                        //       shop_domain: self.$route.query.shop_domain
                        //     }
                        //   });
                        // } else {
                        } else {
                          self.$swal({
                            icon: "success",
                            title: "¡Chatbot creado!",
                            timer: 1000,
                            showConfirmButton: false
                          });

                          // self.$emit("nextStep");
                          self.$router.push({
                            query: {
                              name: "implement",
                              shop_domain: self.platform_shop,
                              token_access: self.platform_access
                            }
                          });
                        }

                        // self.ultimoPaso();

                        chatbot_api
                          .post("/implementar", {
                            id: bot.data.id,
                            token: bot.data.token,
                            token_access: self.$route.query.token_access
                          })
                          .then(response => {
                            // window.open("https://ia.databot.cl/dashboard");
                            console.log("Genial");
                            self.getBots();
                          })
                          .catch(error => console.log(error));

                        self.$swal({
                          icon: "success",
                          title: "¡Chatbot creado!",
                          timer: 1000,
                          showConfirmButton: false
                        });
                      })
                      .catch(error => console.error("error ->", error));
                  })
                  .catch(error => console.error("error ->", error));
              })
              .catch(error => console.error("error ->", error));
            // Fin lo nuevo
          })
          .catch(error => {
            self.loading = false;
            console.log("error -> ", error);
          });
      }
    },

    checkRequiredData() {
      if (!this.name) {
        this.error.name = true;
        return false;
      }
      return true;
    },

    // Esto es lo que estaba en BusinessType.vue
    // TODO mejorar
    ultimoPaso() {
      try {
        const self = this;
        dashboard_api
          .post("/mails/landing", {
            name: self.user.name,
            email: self.user.email,
            phone: self.user.phone,
            mensaje: self.user.url
          })
          .then(responseemail => {
            chatbot_api
              .get("/getuser", {
                params: {
                  email: self.user.email
                }
              })
              .then(async getdatauser => {
                if (getdatauser.data.error) {
                  chatbot_api
                    .post("/sendlead", {
                      email: self.user.email,
                      name: self.user.name,
                      web: self.user.url,
                      phone: self.user.phone
                    })
                    .then(async response => {
                      // realiza la busqueda de la empresa
                      const getcompany = await self.getcompany();

                      if (getcompany.data.response.results != "") {
                        // Agrega el lead a la empresa
                        await self.addleadcompany(response, getcompany);

                        await self.newbusiness(getcompany, response);
                      } else {
                        // crea la empresa
                        await self.createcompany();

                        // realiza la busqueda de la empresa
                        const getcompanycreate = await self.getcompany();

                        if (getcompanycreate.data.response.results.length == 1) {
                          await self.addleadcompany(response, getcompanycreate);

                          await self.newbusiness(getcompanycreate, response);
                        } else {
                          const companyresults = getcompanycreate.data.response.results;

                          companyresults.forEach(async element => {
                            if (element.properties.name) {
                              if (element.properties.name.value == self.user.url) {
                                // eliminar compañia
                                await self.deletebusiness(element);

                                const getcompanycreate2 = await self.getcompany();

                                await self.newbusiness(getcompanycreate2, response);
                              }
                            } else {
                            }
                            // end foreach
                          });
                        }
                      }
                    })
                    .catch(error => console.log("errorrrrrrrrr =>", error));
                } else {
                  const getcompany = await self.getcompany();
                  if (getcompany.data.response.results != "") {
                    // realiza el agregar
                    await self.addleadcompany(getdatauser, getcompany);

                    await self.newbusiness(getcompany, getdatauser);
                  } else {
                    // crea la empresa
                    await self.createcompany();

                    const getcompanycreate = await self.getcompany();

                    if (getcompanycreate.data.response.results.length == 1) {
                      // realiza el agregar
                      await self.addleadcompany(getdatauser, getcompanycreate);
                      // crea el negocio
                      await self.newbusiness(getcompanycreate, getdatauser);
                    } else {
                      const companyresults = getcompanycreate.data.response.results;
                      companyresults.forEach(async element => {
                        if (element.properties.name) {
                          if (element.properties.name.value == self.user.url) {
                            await self.deletebusiness(element);

                            // realiza la busqueda de la empresa
                            const getcompanycreate2 = await self.getcompany();

                            // crea el negocio
                            await self.newbusiness(getcompanycreate2, getdatauser);
                          }
                        } else {
                        }
                      });
                    }
                  }
                }
              });
          })
          .catch(error => console.log("error =>", error));
      } catch (error) {
        console.log(error);
      }
    },

    createcompany() {
      const self = this;
      return new Promise((resolve, reject) => {
        chatbot_api
          .post("/createcompany", {
            name: self.user.url
          })
          .then(async company => {
            resolve(company);
          })
          .catch(error => reject(error));
      });
    },

    getcompany() {
      return new Promise((resolve, reject) => {
        chatbot_api
          .get("/getcompany", {
            params: {
              domain: this.user.url
            }
          })
          .then(getcompany => {
            resolve(getcompany);
          })
          .catch(error => reject(error));
      });
    },

    deletebusiness(element) {
      chatbot_api
        .delete("/deletebusiness", {
          params: {
            id: element.companyId
          }
        })
        .then(async responsse => {});
    },

    addleadcompany(response, getcompany) {
      return new Promise((resolve, reject) => {
        chatbot_api
          .put("/addleadcompany", {
            client: response.data.response.vid,
            company: getcompany.data.response.results[0].companyId
          })
          .then(addcompany => {
            resolve(addcompany);
          })
          .catch(error => reject(error));
      });
    },
    newbusiness(getcompany, response) {
      const self = this;
      return new Promise((resolve, reject) => {
        chatbot_api
          .post("/newbusiness", {
            companiid: getcompany.data.response.results[0].companyId,
            clientid: response.data.response.vid,
            domain: self.user.url,
            from: self.$route.name
          })
          .then(business => {
            // undefined dealID
            if (business.data && business.data.response && business.data.response.dealId) {
              dashboard_api
                .put(`/bot/${self.registerData.bot}`, {
                  chatbot: {
                    id: self.registerData.bot,
                    hubspot_deal_id: business.data.response.dealId
                  }
                })
                .then(response => {})
                .catch(error => console.log("error ->", error));
            }
          })
          .catch(error => reject(error));
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.primer-chatbot {
  width: 100%;

  &__title {
    font-size: 35px;
    margin-bottom: 0;
  }
  .card {
    width: 100%;
    padding: 1rem 0;
    border: none;
    box-shadow: none;
    background-color: transparent;
    z-index: 3;

    .formulario {
      border: none;

      @media (max-width: 480px) {
        input {
          height: 50px;
          font-size: 1.12rem;
        }
        .toggle_card {
          height: 50px;

          p {
            font-size: 1rem;
          }
        }
      }
      .contenedor__color {
        padding: 1rem;

        .temas__item-color {
          display: flex;
          margin-top: 1rem;

          input {
            height: 53px;
            width: 50px;
            padding: 0;
            border: none;
            box-shadow: none;
            border-radius: none;
            background: transparent;
            cursor: pointer;

            &[type="color"]::-moz-color-swatch {
              box-shadow: none;
              border-radius: 5px;
            }
            &[type="color"]::-webkit-color-swatch-wrapper {
              border-radius: 5px;
              border-style: none;
            }
            // edita la forma del input color
            &[type="color"]::-webkit-color-swatch {
              box-shadow: none;
              border-radius: 5rem;
              border: 1px solid #cdcdcd;
            }
          }
        }
      }
    }
  }
  .btn-base {
    padding: 0.75rem 2rem;
  }
  label {
    font-size: 1rem;
  }
  .chatbot-done {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 345px;

    margin: 8rem auto 2rem;

    @media (max-width: 320px) {
      width: calc(345px - 32px);
    }
    .iso-logo {
      width: 5rem;
      margin-bottom: 2rem;
    }
  }
}
</style>
