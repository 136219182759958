<template>
  <FadeTransition :key="my_register.step" :duration="200" origin="center top">
    <section class="proceso-registro">
      <FadeTransition :key="my_register.step" :duration="200" origin="center top">
        <FormRegistro
          type="register"
          v-if="my_register.step == 1 && logged == 'false'"
          :step="my_register.step"
          @nextStep="setStep"
          @sendChatbotWeb="web = $event"
        />
        <FirstBot
          v-if="my_register.step == 2 && logged == 'true'"
          :step="my_register.step"
          :web="web"
          @nextStep="setStep"
        />
        <!-- <Subscription
          v-if="my_register.step == 3 && logged == 'true'"
          :step="my_register.step"
          @nextStep="setStep"
        /> -->
        <Implement
          v-if="my_register.step == 3"
          :step="my_register.step"
          :bot_id="bot_id"
          @abort="navigateStep"
        />
      </FadeTransition>
    </section>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import { mapMutations, mapState } from "vuex";
import FormRegistro from "@/components/Form_Registro.vue";
import FirstBot from "./components/FirstBot.vue";
import Implement from "./components/Implement.vue";
import Subscription from "./components/Subscrtiption.vue";

export default {
  name: "register",
  components: {
    FormRegistro,
    FirstBot,
    Implement,
    FadeTransition,
    Subscription
  },
  data() {
    return {
      model: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        terms_confirmation: false
      },
      accountId: 0,
      my_register: {},
      web: ""
    };
  },
  created() {
    this.my_register = this.registerData;

    // parametro de la url
    if (this.bot_id) {
      this.my_register.step =
        this.logged == "true"
          ? this.$router.push({
              name: "register",
              query: { activar: "activate", bot_id: this.bot_id }
            })
          : this.$router.push({
              name: "login",
              query: { login: "login", bot_id: this.bot_id }
            });
      this.my_register.step = 3;
    } else {
      this.my_register.step = this.logged == "true" ? this.my_register.step : 1;

      // validacion - usuario se intenta registrar pero ya tiene una cuenta logueada cierra las sesiones y lo devuelve al login
      if (this.logged == "true" && this.user.botIds.length > 0) {
        this.logout();
        this.$router.push("/login");
      }
    }
    this.setRegisterProcess(this.my_register);
  },
  mounted() {
    // smooth scroll desde una landing a otra
    if (this.$route.query.seccion) {
      this.scrollTo(2000);
    }
  },
  computed: {
    ...mapState(["logged", "registerData", "user"]),

    bot_id() {
      return this.$route.query.bot_id;
    }
  },
  methods: {
    ...mapMutations(["setRegisterProcess", "logout"]),

    setStep() {
      this.my_register.step++;
      // console.log(this.my_register.step);
      this.setRegisterProcess(this.my_register);
    },
    // smooth scroll
    scrollTo(millis) {
      this.$scrollTo(`#${this.$route.query.seccion}`, millis);
    },
    navigateStep(val) {
      switch (val) {
        case 1:
          break;
        case 2:
          if (this.logged == "true") {
            this.my_register.step = val;
            this.setRegisterProcess(this.my_register);
          }
          break;
        case 3:
          if (this.my_register.bot) {
            this.my_register.step = val;
            this.setRegisterProcess(this.my_register);
          }
          break;
      }
    },
    setAccountId(id) {
      this.accountId = id;
    },
  },
  watch: {
    $route(to, from) {
      this.scrollTo(2000);
    }
  }
};
</script>

<style lang="scss" scoped>
.proceso-registro {
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;

  @media (max-width: 991px) {
    height: 100%;
  }
}
</style>

