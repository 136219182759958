<template>
  <section class="recover-pass">
    <div class="text-center my-5">
      <h1 class="title">Nueva contraseña</h1>
    </div>
    <!-- Page content -->
    <div class="card">
      <div class="card__body">
        <label>Nueva contraseña</label>
        <input
          placeholder="Contraseña"
          type="password"
          maxlength="15"
          v-model="model.password"
          required
        />
        <p class="text_error" v-if="error == 'pass_0'">Ingresa una contraseña
        </p>
        <label>Repite tu nueva contraseña</label>
        <input
          placeholder="Repite tu contraseña"
          type="password"
          maxlength="15"
          v-model="model.password_confirmation"
          required
        />
        <p class="text_error" v-if="error == 'password_not_match'">Tus contraseñas no coinciden
        </p>
        <p class="text_error" v-if="error == 'pass_length'">Que tu contraseña tenga al menos 6 caracteres
        </p>
        <div class="text-center">
          <button
            class="btn btn-base second lg my-4"
            @click="register"
            >Guardar contraseña
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import dashboard_api from '../dashboard_api.js';

export default {
  name: 'password_resets',

  data() {
    return {
      model: {
        password: '',
        password_confirmation: '',
        token: '',
      },
      error: '',
    };
  },

  computed: {
    ...mapState(['logged']),
  },

  methods: {
    register() {
      const self = this;
      const {
        password,
        password_confirmation,
      } = self.model;

     	if (password != password_confirmation) self.error = 'password_not_match';

      else if (password.length == 0) self.error = 'pass_0';

      else if (password_confirmation.length == 0) self.error = 'pass_0';

      else if (password_confirmation.length < 6) self.error = 'pass_length';

      else {
        self.error = '';
        self.model.token = self.$route.params.token;

        dashboard_api.post('/password/reset', {
          password: self.model.password,
          token: self.model.token,
        })
          .then((result) => {
            if (result.data.status == 'ok') {
              self.$swal({
                icon: 'success',
                title: 'Has cambiado de contraseña correctamente',
                html:
                  '<div class="d-flex justify-content-center mt-4"><a class="btn btn-base lg" href="https://ia.databot.cl/login">Iniciar sesión</a></div> ',
                showConfirmButton: false,
              });
              console.log('Listo >');
            }
            // self.$router.push("/login");
          })
          .catch((err) => {
            console.log('error', err);

            self.$swal({
              icon: 'error',
              title: 'Error al cambiar tu contraseña',
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.recover-pass {
  width: 100%;

  .title {
    font-size: 35px;
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
  .card {
    box-shadow: none;
    margin: auto;
    border: none;
    width: 450px;
    z-index: 3;

    @media (max-width: 480px) {
      width: 310px;
    }
    &__body {
      padding: 1rem;

      .formulario {
        justify-content: center;
        margin: auto;

        label {
          font-size: 1rem;
        }
      }
    }
  }
}
</style>
