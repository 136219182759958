<template>
  <section class="implement text-center">
    <FadeTransition :duration="200">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="item" id="code" v-show="ShowCode">
            <h2 class="mt-4 mb-2">
              <i class="fas fa-code mr-2" />Código de implementación
            </h2>
            <div class="mt-5">
              <p>{{ implementMessage }}</p>
              <div class="area_box">
                <textarea
                  class="code_text"
                  id="scriptBot"
                  cols="30"
                  rows="5"
                  v-model="implementation_script"
                  @click="copyTestingCode(implementation_script)"
                />
                <span @click="copyTestingCode" class="btn-copy"
                  ><i class="far fa-clone mr-2" />Copiar</span
                >
              </div>
            </div>
          </div>

          <div class="item" id="wordpress" v-show="ShowWordpress">
            <img
              class="logo mb-4"
              src="/img/logos/2-implementacion/wordpress.svg"
              alt=""
            />
            <p>
              Descarga y sube el archivo en la sección
              <span class="negrita">plugins</span> dentro de wordpress.
            </p>
            <div class="item__box mt-4 py-4 bg_grey">
              <p class="text-token">
                Id: <span class="negrita">{{ registerData.bot }}</span>
              </p>
              <p class="text-token mb-0">
                Token: <span class="negrita">{{ registerData.botToken }}</span>
              </p>
            </div>
            <div class="btn_box">
              <br />
              <a href="/img/archivos/databot_wordpress.zip">
                <button type="download" class="btn-base outline">
                  Descargar zip
                </button>
              </a>
            </div>
          </div>

          <div class="item" id="vtex" v-show="ShowVtex">
            <img
              class="logo mb-4"
              src="/img/logos/2-implementacion/vtex.svg"
              alt=""
            />
            <p>
              Ingrese estos valores en la
              <span class="negrita">app</span> de Databot dentro de Vtex.
            </p>
            <div class="item__box mt-4 py-4 bg_grey">
              <p class="text-token">
                ID: <span class="negrita">{{ registerData.bot }}</span>
              </p>
              <p class="text-token mb-0">
                Token: <span class="negrita">{{ registerData.botToken }}</span>
              </p>
            </div>
          </div>

          <div class="item" id="jumpseller" v-show="ShowJumpseller">
            <img
              class="logo mb-4"
              src="/img/logos/2-implementacion/jumpseller.svg"
              alt=""
            />
            <p class="mb-0">
              Ingresa el nombre de tu tienda, presiona el botón e inicia sesión
              dentro de la plataforma. ¡Verifica que sea el nombre correcto!
            </p>
            <div class="item__box my-4 p-4 bg_grey">
              <input
                type="text"
                placeholder="Nombre de tu tienda"
                class="no_input"
                v-model="ShopName"
              />
            </div>
            <div class="btn_box">
              <div>
                <button class="btn-base outline" v-if="ShopName == 0" disabled>
                  Ir a jumpseller
                </button>
                <a
                  v-else
                  target="_blank"
                  :href="`https://${ShopName}.jumpseller.com/admin/en/apps/overview/databot-cl`"
                  class="btn btn-base"
                >
                  Ir a jumpseller
                </a>
              </div>
            </div>
          </div>

          <div class="item" id="vtex" v-show="ShowShopify">
            <img
              class="logo mb-4"
              src="/img/logos/2-implementacion/shopify.svg"
              alt=""
            />
            <p>
              Ingrese estos valores en la
              <span class="negrita">app</span> de Databot.
            </p>
            <div class="item__box mt-4 py-4 bg_grey">
              <p class="text-token">
                ID: <span class="negrita">{{ registerData.bot }}</span>
              </p>
              <p class="text-token mb-0">
                Token: <span class="negrita">{{ registerData.botToken }}</span>
              </p>
            </div>
          </div>

          <div class="item" id="gtm" v-show="ShowGtm">
            <h2 class="mt-4 mb-2">
              <img
                class="logo mb-4"
                src="/img/logos/2-implementacion/google-tag-manager.svg"
                alt=""
              />
              <p>Google Tag Manager</p>
            </h2>
            <div class="mt-5">
              <p>Agregue el siguiente código en una etiqueta de tipo "HTML personalizado</p>
              <div class="area_box">
                <textarea
                  class="code_text"
                  id="scriptBotGtm"
                  cols="30"
                  rows="5"
                  v-model="google_tag_manager_script"
                  @click="copyTestingCode(google_tag_manager_script)"
                />
                <span @click="copyTestingCode(google_tag_manager_script)" class="btn-copy"
                  ><i class="far fa-clone mr-2" />Copiar</span
                >
              </div>
            </div>
          </div>

          <div class="item" id="mail" v-show="ShowMail">
            <h2 class="mt-4 mb-2">
              <i class="fas fa-paper-plane mr-2" />Enviar a mi desarrollador
            </h2>
            <br />
            <p class="mb-0">
              Puedes enviar el código a los desarrolladores para que puedan
              implementarlo en el sitio web.
            </p>
            <div class="item__box my-4 p-4 bg_grey">
              <input
                required
                type="email"
                class="no_input"
                placeholder="Enviar a nombre@empresa.cl"
                v-model="email"
              />
            </div>
            <p class="text_error" v-if="error.email">
              Debes completar este campo.
            </p>
            <p class="text_error" v-if="error.email_2">
              Ingresa un correo válido.
            </p>
            <button
              class="btn-base outline"
              @click="sendMail()"
              :disabled="loading"
            >
              Enviar código
            </button>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-4">
              <button
                class="btn-option"
                :class="{ active: ShowCode }"
                @click="Code"
              >
                <i class="fas fa-code" />
                <span>Código</span>
              </button>
            </div>
            <div class="col-lg-4">
              <button
                class="btn-option"
                :class="{ active: ShowShopify }"
                @click="CodeShopify"
              >
                <img
                  src="/img/logos/2-implementacion/shopify.svg"
                  class="button-img"
                  alt=""
                />
              </button>
            </div>
            <div class="col-lg-4">
              <button
                class="btn-option"
                :class="{ active: ShowWordpress }"
                @click="CodeWordpress"
              >
                <img
                  src="/img/logos/2-implementacion/wordpress.svg"
                  class="button-img"
                  alt=""
                />
              </button>
            </div>
            <div class="col-lg-4">
              <button
                class="btn-option"
                :class="{ active: ShowVtex }"
                @click="CodeVtex"
              >
                <img
                  src="/img/logos/2-implementacion/vtex.svg"
                  class="button-img"
                  alt=""
                />
              </button>
            </div>
            <div class="col-lg-4">
              <button
                class="btn-option"
                :class="{ active: ShowJumpseller }"
                @click="CodeJumpseller"
              >
                <img
                  src="/img/logos/2-implementacion/jumpseller.svg"
                  class="button-img"
                  alt=""
                />
              </button>
            </div>           
            <div class="col-lg-4">
              <button
                class="btn-option"
                :class="{ active: ShowGtm }"
                @click="CodeGtm"
              >
                <img
                  src="/img/logos/2-implementacion/google-tag-manager.svg"
                  class="button-img"
                  alt=""
                />
              </button>
            </div>
            <p class="col-lg-12 btn-text text-center" @click="ViewSendMail">
              <i class="fas fa-paper-plane mr-2" />Enviar por correo
            </p>
          </div>
          <div class="row justify-content-center my-4">
            <router-link to="/dashboard" class="btn btn-base second">
              Ver mi Dashboard
            </router-link>
          </div>
        </div>
      </div>
    </FadeTransition>
  </section>
</template>
<script>
import { FadeTransition, SlideYUpTransition } from 'vue2-transitions';
import { mapState } from 'vuex';
import dashboard_api from '@/dashboard_api.js';
import BulletSteps from './BulletSteps';
import environment from '@/environment.js';

export default {
  name: 'implement',
  props: ['step'],
  components: {
    BulletSteps,
    FadeTransition,
    SlideYUpTransition,
  },

  data() {
    return {
      ShowCode: true,
      ShowWordpress: false,
      ShowVtex: false,
      ShowJumpseller: false,
      ShowMail: false,
      ShowGtm: false,
      ShowShopify: false,
      email: '',
      ShopName: '',
      bot: {},
      loading: false,
      error: {
        email: false,
        email_2: false,
      },
      implementMessage:
        'Agrega el siguiente código en el archivo html, antes de la línea </body>.',
    };
  },

  mounted() {
    this.getBotInfo();
  },
  computed: {
    ...mapState(['user', 'plan', 'registerData']),

    implementation_script() {
      return (
        `<script defer type='text/javascript' src='${environment.url_env_bot_front}/dist_files/databot.js' id='${this.registerData.bot}' bot='${this.registerData.botToken}'></sc` +
        `ript><link rel='stylesheet' href='${environment.url_env_bot_front}/dist_files/databot.css'>`
      );
    },
    google_tag_manager_script() {
      const scriptSrc = `${environment.url_env_bot_front}/dist_files/databot.js`;
      const scriptId = this.registerData.bot;
      const scriptBotAttribute = this.registerData.botToken;

      return `<script defer type='text/javascript'>
        var script = document.createElement('script');
        script.defer = true;
        script.type = 'text/javascript';
        script.src = '${scriptSrc}';
        script.id = '${scriptId}';
        script.setAttribute('bot', '${scriptBotAttribute}');
        document.head.appendChild(script);
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '${environment.url_env_bot_front}/dist_files/databot.css';
        document.head.appendChild(link);
      <\/script>`;
    }
  },
  methods: {
    getBotInfo() {
        dashboard_api
          .get(`get_bot_info/${this.registerData.bot}`)
          .then((response) => (this.bot = response.data));
    },
    checkShop() {
      if (!this.ShopName) {
        this.error.ShopName = true;
        return false;
      }
      return true;
    },
    Code() {
      this.ShowCode = true;
      this.ShowWordpress = false;
      this.ShowVtex = false;
      this.ShowJumpseller = false;
      this.ShowMail = false;
      this.ShowGtm = false;
      this.ShowShopify = false;
    },
    CodeWordpress() {
      this.ShowCode = false;
      this.ShowWordpress = true;
      this.ShowVtex = false;
      this.ShowJumpseller = false;
      this.ShowMail = false;
      this.ShowGtm = false;
      this.ShowShopify = false;
    },
    CodeVtex() {
      this.ShowCode = false;
      this.ShowWordpress = false;
      this.ShowVtex = true;
      this.ShowJumpseller = false;
      this.ShowMail = false;
      this.ShowGtm = false;
      this.ShowShopify = false;
    },
    CodeJumpseller() {
      this.ShowCode = false;
      this.ShowWordpress = false;
      this.ShowVtex = false;
      this.ShowJumpseller = true;
      this.ShowMail = false;
      this.ShowGtm = false;
      this.ShowShopify = false;
    },
    CodeGtm() {
      this.ShowCode = false;
      this.ShowWordpress = false;
      this.ShowVtex = false;
      this.ShowJumpseller = false;
      this.ShowMail = false;
      this.ShowGtm = true;
      this.ShowShopify = false;
    },
    CodeShopify() {
      this.ShowCode = false;
      this.ShowWordpress = false;
      this.ShowVtex = false;
      this.ShowJumpseller = false;
      this.ShowMail = false;
      this.ShowGtm = false;
      this.ShowShopify = true;
    },
    ViewSendMail() {
      this.ShowCode = false;
      this.ShowWordpress = false;
      this.ShowVtex = false;
      this.ShowJumpseller = false;
      this.ShowMail = true;
      this.ShowGtm = false;
      this.ShowShopify = false;
    },
    sendMail() {
      if (this.checkRequiredData()) {
        const self = this;
        // console.log('Aquí', self.bot.id, self.email );
        self.loading = true;

        dashboard_api
          .post('/mails/implement', {
            email: self.email,
            bot_id: self.bot.id,
          })

          .then((result) => {
            self.loading = false;
            self.$swal({
              icon: 'success',
              title: '¡Mensaje enviado',
              timer: 1000,
              timerProgressBar: false,
              showConfirmButton: false,
              // show,
            });
            self.$router.push({ name: 'dashboard' });
          })
          .catch((error) => console.log('error ->', error));
        // console.log('Funciona');
      }
    },

    checkRequiredData() {
      if (!this.email) {
        this.error.email = true;
        return false;
      }
      if (!this.email.includes('@')) {
        this.error.email_2 = true;
        return false;
      }
      return true;
    },

    copyTestingCode(code) {
      navigator.clipboard.writeText(code);

      try {
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Copiado',
          timer: 1000,
          timerProgressBar: false,
          showConfirmButton: false,
        });
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Hubo un problema, vuelve a intentarlo',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.implement {
  .no_input {
    font-size: 1rem;
  }
  .logo {
    width: 150px;
  }
  .item {
    border: 1px solid #cdcdcd;
    border-radius: 1rem;
    justify-content: center;
    margin: 2rem auto 1rem;
    padding: 1.5rem;
    transition: 0.5s;

    &__box {
      background-color: #fafafa;
      border: 1px solid #e8e8e8;
      border-radius: 0.5rem;
    }
    .area_box {
      position: relative;
    }
  }

  textarea {
    position: relative;
    height: fit-content;
    padding: 2rem;
    background-color: #fafafa;
    color: #181818;
    margin: 0;
    z-index: 1;
    cursor: pointer;

    &:hover {
      + .btn-copy {
        visibility: visible;
        opacity: 1;
        transition: 0.5s;
      }
    }
  }
  .code_text {
    height: fit-content !important;
  }
  .btn-copy {
    background-color: #fafafa;
  }
  .btn-option {
    padding: 0.75rem 1.5rem;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1600px) {
      padding: 0.25rem 1rem;
      height: 60px;
    }
    span {
      font-size: 12px;
      color: #181818;
    }
  }
  .button-img {
    @media (min-width: 991px) {
      padding: 0;
      height: auto;
    }
  }
  .pl-0,
  .pr-0 {
    @media (max-width: 991px) {
      padding: 0;
    }
  }
  .disabled {
    color: #cdcdcd;
  }
  .text-token {
    font-size: 1rem;
  }
  .active {
    background: #fafafa;
    box-shadow: none;
  }
  .btn-base {
    padding: 0.75rem 2rem;
  }
}
</style>
