<template>
  <section class="new-rol">
    <div class="container mb-5">
      <div class="row mt-4">
        <div class="col-lg-4 pl-0">
          <p v-if="error == 'empty_name'" class="text_error">Debes ingresar un nombre</p>
          <label v-else>Nombre del nuevo Rol</label>
          <input
            class="mb-4"
            :class="{ input_error: error == 'empty_name' }"
            placeholder="Ejm: Admin"
            v-model="model.name"
          />
        </div>
      </div>
      <div class="card card-table">
        <table
          class="tabla__agentes"
          ref="table"
        >
          <thead>
            <th>Acceso</th>
            <th class="text-center"><i class="fas fa-plus" /> Crear</th>
            <th class="text-center"><i class="fas fa-eye" /> Ver</th>
            <th class="text-center"><i class="fas fa-pen" /> Editar</th>
            <th class="text-center"><i class="fas fa-trash" /> Eliminar</th>
          </thead>

          <tbody>
            <tr>
              <td class="negrita pl-4">Chatbot</td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="bot_create"
                  value="bot_create"
                  v-model="model.bot_create"
                />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="bot_read"
                  value="bot_read"
                  v-model="model.bot_read"
                />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="bot_update"
                  value="bot_update"
                  v-model="model.bot_update"
                />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="bot_delete"
                  value="bot_delete"
                  v-model="model.bot_delete"
                />
              </td>
            </tr>

            <tr>
              <td class="negrita pl-4">Planes</td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="plan_create"
                  value="plan_create"
                  v-model="model.plan_create"
                />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="plan_read"
                  value="plan_read"
                  v-model="model.plan_read"
                />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="plan_update"
                  value="plan_update"
                  v-model="model.plan_update"
                />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="plan_delete"
                  value="plan_delete"
                  v-model="model.plan_delete"
                />
              </td>
            </tr>

            <tr>
              <td class="negrita pl-4">Cliente</td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="cliente_create"
                  value="cliente_create"
                  v-model="model.cliente_create"
                />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="cliente_read"
                  value="cliente_read"
                  v-model="model.cliente_read"
                />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="cliente_update"
                  value="cliente_update"
                  v-model="model.cliente_update"
                />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="cliente_delete"
                  value="cliente_delete"
                  v-model="model.cliente_delete"
                />
              </td>
            </tr>
            <tr class="text-left">
              <td class="negrita pl-4">Databot live</td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="databot_read"
                  value="databot_read"
                  v-model="model.databot_read"
                />
              </td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
            </tr>
            <tr class="text-left">
              <td class="negrita pl-4">Conversaciones</td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="conver_read"
                  value="conver_read"
                  v-model="model.conver_read"
                />
              </td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
            </tr>
            <tr class="text-left">
              <td class="negrita pl-4">CRM</td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="conver_read"
                  value="conver_read"
                  v-model="model.crm"
                />
              </td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
            </tr>
            <tr class="text-left">
              <td class="negrita pl-4">Preguntas Frecuentes</td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="conver_read"
                  value="conver_read"
                  v-model="model.questions"
                />
              </td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
            </tr>
            <tr class="text-left">
              <td class="negrita pl-4">WhatsApp</td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="conver_read"
                  value="conver_read"
                  v-model="model.whatsapp"
                />
              </td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
              <td>
                <p class="text-center mb-0">-</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row justify-content-between my-4">
        <router-link class="btn-text" to="/role/list"
          ><i class="fas fa-arrow-left mr-2" />Volver</router-link>
        <button class="btn btn-base" @click="saveRol">
          Crear rol
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import dashboard_api from "@/dashboard_api.js";

export default {
  name: "CrearRol",

  data() {
    return {
      model: {
        name: "",
        bot_create: false,
        bot_read: false,
        bot_update: false,
        bot_delete: false,
        plan_create: false,
        plan_read: false,
        plan_update: false,
        plan_delete: false,
        cliente_create: false,
        cliente_read: false,
        cliente_update: false,
        cliente_delete: false,
        databot_read: false,
        conver_read: false,
        crm: false,
        questions: false,
        whatsapp: false,
      },
      error: "",
    };
  },
  methods: {
    saveRol() {
      const self = this;
      self.error = "";
      self.loading = true;

      if (self.model.name == "") {
        self.error = 'empty_name';
      } else {
        self.error = '';

        dashboard_api
          .post("/role/rolenew", {
            name: self.model.name,
            bot_id: self.bot_id,
            bot_create: self.model.bot_create,
            bot_read: self.model.bot_read,
            bot_update: self.model.bot_update,
            bot_delete: self.model.bot_delete,
            plan_create: self.model.plan_create,
            plan_read: self.model.plan_read,
            plan_update: self.model.plan_update,
            plan_delete: self.model.plan_delete,
            cli_create: self.model.cliente_create,
            cli_read: self.model.cliente_read,
            cli_update: self.model.cliente_update,
            cli_delete: self.model.cliente_delete,
            databot_read: self.model.databot_read,
            conver_read: self.model.conver_read,
            crm: self.model.crm,
            questions: self.model.questions,
            whatsapp: self.model.whatsapp,
          })
          .then((result) => {
            // console.log(result);
            if (result.statusText == "Created") {
              self.$swal({
                icon: "success",
                title: "Rol Guardado",
              });
            }
            self.model.name = "";
            self.selected_bot.id = "";
            self.model.bot_create = "";
            self.model.bot_read = "";
            self.model.bot_update = "";
            self.model.bot_delete = "";
            self.model.plan_create = "";
            self.model.plan_read = "";
            self.model.plan_update = "";
            self.model.plan_delete = "";
            self.model.cliente_create = "";
            self.model.cliente_read = "";
            self.model.cliente_update = "";
            self.model.cliente_delete = "";
            self.model.databot_read = "";
            self.model.conver_read = "";
            self.model.crm = "";
            self.model.questions = "";
            self.model.whatsapp = "";
          })
          .catch((err) => {
            if (err == "Error: Not Acceptable") {
              self.$swal({
                icon: "error",
                title: "El nombre del rol ya esta ingresado a este bot",
              });
            } else {
              self.$swal({
                icon: "error",
                title: "Error al crear el rol",
              });
            }
          });
      }
    },
  },

  computed: {
    ...mapState(["user", "selected_bot"]),
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.new-rol {
  .sm {
    width: 20%;

    @media (max-width: 640px) {
      width: 100%;
    }
  }
  .card {
    padding: 0;
  }
  .card-table {
    max-height: 100%;
    height: auto;
    overflow-x: visible;
  }
  table {
    thead {
      color: #181818;

      th {
        padding: 0 1.5rem;
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #e8e8e8;

        &:last-child {
          border-bottom: none;
        }
      }
      td {
        height: 50px;
      }
      input[type='checkbox'] {
        padding: 0 1.5rem;
      }
    }
  }
}
</style>
