<template>
  <section class="roles">
    <div class="container">
      <div class="row mb-4 justify-content-between align-items-center">
        <h2 class="title mb-0">Administra tus roles</h2>
        <router-link to="/role/new" class="btn btn-base"><i class="fas fa-plus" />Nuevo rol</router-link>
      </div>
      <div class="card card-table">
        <base-table
          class="tabla__agentes"
          :data="roles"
          ref="table"
        >
          <template slot="columns">
            <th>Nombre Rol</th>
            <th>Chatbot</th>
            <th>Plan</th>
            <th>Clientes</th>
            <th>Databot Live</th>
            <th>Conversaciones</th>
            <th>CRM</th>
            <th>Preguntas</th>
            <th>Whatsapp</th>
            <th>Acciones</th>
          </template>

          <template slot-scope="{ row }">
            <td>{{ row.name }}</td>
            <td>
              <i
                :class="row.bot_create ? 'text-success' : 'text-gris'"
                class="fas fa-plus"
              />
              <i
                :class="row.bot_read ? 'text-success' : 'text-gris'"
                class="fas fa-eye"
              />
              <i
                :class="row.bot_update ? 'text-success' : 'text-gris'"
                class="fas fa-pen"
              />
              <i
                :class="row.bot_delete ? 'text-success' : 'text-gris'"
                class="fas fa-pen"
              />
            </td>
            <td>
              <i
                :class="row.plan_create ? 'text-success' : 'text-gris'"
                class="fas fa-plus"
              />
              <i
                :class="row.plan_read ? 'text-success' : 'text-gris'"
                class="fas fa-plus"
              />
              <i
                :class="row.plan_update ? 'text-success' : 'text-gris'"
                class="fas fa-pen"
              />
              <i
                :class="row.plan_delete ? 'text-success' : 'text-gris'"
                class="fas fa-trash"
              />
            </td>
            <td>
              <i
                :class="row.cli_create ? 'text-success' : 'text-gris'"
                class="fas fa-plus"
              />
              <i
                :class="row.cli_read ? 'text-success' : 'text-gris'"
                class="fas fa-plus"
              />
              <i
                :class="row.cli_update ? 'text-success' : 'text-gris'"
                class="fas fa-pen"
              />
              <i
                :class="row.cli_delete ? 'text-success' : 'text-gris'"
                class="fas fa-trash"
              />
            </td>
            <td>
              <i
                :class="row.databot_read ? 'text-success' : 'text-gris'"
                class="fas fa-eye"
              />
            </td>
            <td>
              <i
                :class="row.conver_read ? 'text-success' : 'text-gris'"
                class="fas fa-eye"
              />
            </td>
            <td>
              <i
                :class="row.crm ? 'text-success' : 'text-gris'"
                class="fas fa-eye"
              />
            </td>
            <td>
              <i
                :class="row.questions ? 'text-success' : 'text-gris'"
                class="fas fa-eye"
              />
            </td>
            <td>
              <i
                :class="row.whatsapp ? 'text-success' : 'text-gris'"
                class="fas fa-eye"
              />
            </td>
            <td width="10px">
              <a type="button">
                <i @click="mostrar(row.id)" class="fas fa-edit" />
              </a>
              <span @click="deleteRol(row)">
                <i>
                  <img class="table-icons i-delete" src="/img/icons/5-eliminar.svg" alt="Eliminar" />
                </i>
              </span>
            </td>
          </template>
        </base-table>
      </div>
      <div class="col-lg-4 px-0 my-5">
        <router-link class="btn-text" to="/agentes"><i class="fas fa-arrow-left mr-2" />Volver</router-link>
      </div>
    </div>
    <Modal
      :show="show"
      @close="show = false"
    >
      <template slot="header">
        <h2>Editar Roles</h2>
      </template>
      <EditarRol
        slot="content"
        :rol="rol_id"
        @close="show = false"
      />
    </Modal>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import dashboard_api from '@/dashboard_api.js';
import EditarRol from './edit';

export default {
  components: {
    EditarRol,
  },
  data() {
    return {
      roles: [],
      show: false,
      rol_id: 25,
    };
  },
  methods: {
    async getRoles(bot_id) {
      this.roles = (await dashboard_api.get(`/role/role_bot/${bot_id}`)).data;
      console.log('this.roles', this.roles);
    },
    // Borrar un rol de un bot
    deleteRol(rol) {
      const self = this;
      self
        .$swal({
          title: '¿Estás seguro?',
          text: 'No podrás revertir esta acción',
          icon: 'warning',
          buttons: {
            cancel: {
              text: 'Cancelar',
              value: false,
              visible: true,
              className: '',
              closeModal: true,
            },
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true,
            },
          },
        })
        .then((result) => {
          console.log('deleted rol', result);
          if (result) {
            dashboard_api
              .delete(`/role/destroyrole/${rol.id}`)
              .then((response) => {
                let contador = 0;
                self.roles.forEach((element) => {
                  if (element.id == rol.id) {
                    self.roles.splice(contador, 1);
                  }
                  contador += 1;
                });
              });
          }
        });
    },
    mostrar(val) {
      this.rol_id = val;
      console.log('val', val);
      this.show = true;
    },
  },
  // obtener los roles de los bot
  watch: {
    selected_bot(val) {
      this.getRoles(val.id);
    },
  },

  computed: {
    ...mapState(['user', 'selected_bot']),
  },

  created() {
    window.scrollTo(0, 0);
    this.getRoles(this.selected_bot.id);
  },
};
</script>

<style lang="scss" scoped>
.roles {
  .card-table {
    max-height: auto;
    height: auto;
    @media (max-width: 1600px) {
      max-height: auto;
      height: auto;
    }
  }
  .i-edit {
    width: .9rem;
  }
  .i-delete {
    width: .7rem;
  }
}
</style>
