<template>
  <section class="bullet-steps">
    <div class="container">
      <div class="bullet-steps__box">
        <div
          class="item bullet-1"
          :class="step == 1 ? 'active' : ''"
        ></div>
        <div
          class="item bullet-2"
          :class="step == 2 ? 'active' : ''"
        ></div>
        <!-- <div
          class="item bullet-3"
          :class="step == 3 ? 'active' : ''"
        ></div> -->
        <div
          class="item bullet-4"
          :class="step == 3 ? 'active' : ''"
        ></div>
        <!-- <div class="item bullet-4"
          :class="step == 4 ? 'active' : ''"
        ></div> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Steps',
  props: ['step'],
  data() {
    return {
      views: [{}],
    };
  },
};
</script>
<style lang="scss" scoped>
.bullet-steps {
  justify-content: center;

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    .item {
      width: .75rem;
      height: .75rem;
      background-color: #cdcdcd;
      border-radius: 100%;
      margin: .75rem;
    }
    .active {
      background-color: #2981EF;
    }
  }
}
</style>
