<template>
  <section class="inicia-sesion">
    <div class="text-center my-5">
      <h1 class="title">Inicia sesión</h1>
    </div>
    <div class="card">
      <div class="card__body">
        <form role="form" class="formulario">
          <label>Correo</label>
          <input
            class="mb-3"
            placeholder="Ingresa tu correo"
            v-model="model.email"
            autofocus
          />
          <label>Contraseña</label>
          <input
            class="mb-3"
            placeholder="Ingresa tu contraseña"
            type="password"
            v-model="model.password"
          />
          <div class="d-flex align-items-center mb-3">
            <input type="checkbox" />
            <p class="mb-0 ml-2">Recordarme</p>
          </div>
          <div class="d-flex justify-content-center">
            <button
              :disabled="loading"
              @click="login"
              class="btn btn-base second my-4"
            >
              Iniciar sesión
            </button>
          </div>
        </form>
        <v-facebook-login
          :login-options="{
            scope:
              'instagram_manage_messages,pages_messaging,pages_manage_metadata,instagram_manage_comments,instagram_manage_insights,pages_show_list,instagram_basic,pages_read_engagement',
          }"
          @login="facebookLogged"
          app-id="280308108047238"
          class="mb-3 fb-login"
        ></v-facebook-login>
        <div class="text-center mt-5">
          <p>¿Olvidaste tu contraseña?</p>
          <span @click="show = true" class="btn-text p-destacado"
            >Ingresa aquí</span
          >
        </div>
        <Modal ancho="500" :show="show" @close="show = false">
          <template v-slot:content @close="show = false">
            <div class="px-5">
              <label class="my-4"
                >Ingresa tu correo para enviar un enlace de recuperación.</label
              >
              <input
                class="mb-1"
                :class="{ input_error: error == 'empty_mail' }"
                placeholder="nombre@empresa.cl"
                type="mail"
                v-model="email"
                required
              />
              <span v-if="error == 'empty_mail'" class="text_error text-center"
                >Ingresa un correo válido</span
              >
              <div class="text-center">
                <button @click.prevent="passRecover" class="btn btn-base my-4">
                  Enviar enlace
                </button>
              </div>
            </div>
          </template>
        </Modal>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import VFacebookLogin from 'vue-facebook-login-component';
import dashboard_api from '@/dashboard_api.js';

export default {
  name: 'FormLogin',
  components: {
    VFacebookLogin,
  },
  data() {
    return {
      email: '',
      model: {
        email: '',
        password: '',
      },
      error: '',
      loading: false,
      show: false,
    };
  },

  computed: {
    ...mapState(['platform_name']),

    loginq() {
      return this.$route.query.login;
    },
    bot_id() {
      return this.$route.query.bot_id;
    },
  },

  methods: {
    ...mapMutations(['loginAction']),

    login() {
      const self = this;
      self.loading = true;

      self.$swal({
        icon: 'info',
        title: 'Iniciando...',
        showConfirmButton: false,
      });
      self.$swal.showLoading();

      dashboard_api
        .post('/auth/sign_in', {
          email: self.model.email,
          password: self.model.password,
        })
        .then((result) => {
          self.$store.commit('authModule/credentialsStorage', {
            ...result.headers,
          });

          dashboard_api
            .post('/bot/list_bot_ids_by_user', { user_id: result.data.data.id })
            .then((botsResponse) => {
              dashboard_api
                .get(
                  `/client_facturacion_data/by_user_id/${result.data.data.id}`,
                )
                .then((clientDataResponse) => {
                  if (clientDataResponse.data != null) {
                    self.loginAction({
                      user: result.data.data,
                      customerId: clientDataResponse.data.costumer_id,
                      token: result.headers['access-token'],
                      botIds: botsResponse.data,
                    });

                    self.$swal({
                      icon: 'success',
                      title: 'Has iniciado sesión exitosamente',
                      timer: 800,
                      timerProgressBar: false,
                      showConfirmButton: false,
                    });
                    // La ruta donde se dirigira el usuario logeado, variara con su plan
                    if (!self.loginq) {
                      if (self.platform_name == 'jumpseller') {
                        self.$router.push({
                          name: 'integration-install',
                          query: {
                            token_access: self.$route.query.token_access,
                            platform: self.platform_name,
                            shop_domain: self.$route.query.shop_domain,
                          },
                        });
                      } else {
                        self.$router.push('/dashboard');
                      }
                    } else {
                      self.$router.push({
                        name: 'register',
                        query: { activar: 'activate', bot_id: self.bot_id },
                      });
                    }
                  } else {
                    dashboard_api
                      .post('/flow_requests/create_customer', {
                        flow_request: {
                          name: result.data.data.name,
                          email: result.data.data.email,
                          externalId: result.data.data.id,
                          // externalId: `${result.data.data.email}_${result.data.data.id}`
                        },
                      })
                      .then((flowCustomerDataCreateResponse) => {
                        result.data.data.name = 'pruebas';
                        result.data.data.email = 'pruebas@databot.cl';
                        self.loginAction({
                          user: result.data.data,
                          customerId:
                            flowCustomerDataCreateResponse.data.costumer_id,
                          token: result.headers['access-token'],
                          botIds: botsResponse.data,
                        });

                        self.$swal({
                          icon: 'success',
                          title: 'Has iniciado sesión exitosamente',
                          timer: 800,
                          timerProgressBar: false,
                          showConfirmButton: false,
                        });
                        // La ruta donde se dirigira el usuario logeado, variara con su plan
                        if (!self.loginq) {
                          if (self.platform_name == 'jumpseller') {
                            self.$router.push({
                              name: 'integration-install',
                              query: {
                                token_access: self.$route.query.token_access,
                                platform: self.platform_name,
                                shop_domain: self.$route.query.shop_domain,
                              },
                            });
                          } else {
                            self.$router.push('/dashboard');
                          }
                        } else {
                          self.$router.push({
                            name: 'register',
                            query: { activar: 'activate', bot_id: self.bot_id },
                          });
                        }
                      })
                      .catch((error) => console.error('error ->', error));
                  }
                  self.loading = false;
                })
                .catch((error) => console.error('error ->', error));
              // self.loginAction({ user: result.data.data, token: result.headers["access-token"] });
            });
        })
        .catch((error) => {
          self.loading = false;
          self.$swal({
            icon: 'error',
            title: 'Error al logearse',
            text: 'Su correo o contraseña son incorrectos',
          });
        });
    },

    passRecover() {
      const self = this;

      if (self.email === '') {
        self.error = 'empty_mail';
      } else {
        self.$swal({
          icon: 'info',
          title: 'Enviando...',
          showConfirmButton: false,
        });
        self.$swal.showLoading();

        dashboard_api
          .post('/password/forgot', {
            email: self.email,
          })
          .then((response) => {
            if (response.data.status == 'ok') {
              self.$swal({
                icon: 'success',
                title: 'Correo de recuperación enviado',
                text: 'Si no ves el correo, recuerda revisar tu carpeta de spam',
              });
              self.error = '';
              self.show = false;
            }
          })
          .catch((err) => {
            console.log(err);
            self.$swal({
              icon: 'error',
              title: 'Error al enviar el correo',
            });
          });
      }
    },

    facebookLogged(e) {
      console.log('🚀 Aqui *** -> e', e);
      // solo para fines de test, se logea con USUARIO TEST
      const { accessToken, userID } = e.authResponse;
      console.log('🚀 Aqui *** -> userID:', userID);
      console.log('🚀 Aqui *** -> accessToken:', accessToken);
      this.model.email = 'test33@databot.cl';
      this.model.password = '123456';
      this.login();
    },
  },
};
</script>

<style lang="scss">
.fb-login {
  margin: auto;
}

.v-facebook-login.mb-3 span {
  color: white !important; /* Change the text color to white, for example */
}

.inicia-sesion {
  @media (max-width: 480px) {
    margin: 2rem auto;
  }
  .title {
    font-size: 35px;
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
  .card {
    margin: auto;
    border: none;
    width: 450px;
    z-index: 3;

    @media (max-width: 480px) {
      width: 100%;
    }
    &__body {
      padding: 1rem;

      @media (max-width: 480px) {
        padding: 1rem 0;
      }
      .formulario {
        justify-content: center;
        margin: auto;

        @media (max-width: 480px) {
          input {
            height: 50px;
            font-size: 1.12rem;
          }
        }
      }
    }
  }
  .btn-text {
    font-size: 14px;
  }
  .btn-base {
    padding: 0.75rem 2rem;
  }
}
</style>
