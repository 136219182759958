var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.options),function(option,index){return _c('div',{key:option.id},[(option.label == 0 && _vm.error.empty == 'empty_field')?_c('p',{staticClass:"text_error"},[_vm._v(" Completa esta opción ")]):_c('div',{staticClass:"py-3"},[_c('div',{staticClass:"cart-label-top delete-input delete-input-option"},[_c('label',{staticClass:"mt-1 label-cart"},[_vm._v("Opción "+_vm._s(_vm.buttonIndexString ? `${_vm.buttonIndexString}.${index + 1}` : index + 1))]),_c('button',{on:{"click":function($event){return _vm.deleteOption(option, index)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])]),_c('div',{staticClass:"row options-row pt-3 pb-2 activate-select"},[_c('div',{staticClass:"d-flex flex-column col-lg-6"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.label),expression:"option.label"}],class:{
                input_error:
                  option.label == 0 && _vm.error.empty == 'empty_field',
              },attrs:{"type":"text","placeholder":_vm.placeholderOptions[index],"required":""},domProps:{"value":(option.label)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(option, "label", $event.target.value)}}})])]),_c('div',{staticClass:"col-lg-6"},[_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(option.action),expression:"option.action"}],staticClass:"col-12",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(option, "action", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Tipo de botón")]),_vm._l((_vm.optionButton),function(botton,index){return _c('option',{key:index,domProps:{"value":botton}},[_vm._v(" "+_vm._s(_vm.getButtonTypeName(botton))+" ")])})],2)])]),_c('div',{staticClass:"col-lg-12"},[(option.action === 'answer')?_c('select',{directives:[{name:"model",rawName:"v-model",value:(option.selectedIntent),expression:"option.selectedIntent"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(option, "selectedIntent", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Seleccionar intent")]),_vm._l((_vm.intents),function(intent){return _c('option',{key:intent.id,domProps:{"value":intent}},[_vm._v(" "+_vm._s(intent.intent_ui_name)+" ")])})],2):_vm._e()]),(option.action === 'buttons')?_c('div',{staticClass:"col-12 pb-2"},[_c('label',{staticClass:"suboptionMenu"},[_vm._v("Opciones botón")]),_c('RecursiveInitialMenuOptions',{attrs:{"buttonIndex":index + 1,"buttonIndexString":_vm.buttonIndexString
                ? `${_vm.buttonIndexString}.${index + 1}`
                : `${index + 1}`,"options":option.options || [],"level":_vm.level + 1}}),(
              (option.options && option.options.length < 5) || !option.options
            )?_c('button',{staticClass:"btn btn-dash btn-dash-sm m-0 mt-2",on:{"click":function($event){return _vm.addSubOption(option, index)}}},[_vm._v(" + ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"col-lg-12"},[(option.action === 'url')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.url),expression:"option.url"}],staticClass:"col-lg-12",attrs:{"type":"text","placeholder":"Ingresar url"},domProps:{"value":(option.url)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(option, "url", $event.target.value)}}}):_vm._e()])])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }