<template>
  <div>
    <div v-for="(option, index) in options" :key="option.id">
      <p
        class="text_error"
        v-if="option.label == 0 && error.empty == 'empty_field'"
      >
        Completa esta opción
      </p>
      <div v-else class="py-3">
        <div class="cart-label-top delete-input delete-input-option">
          <label class="mt-1 label-cart"
            >Opción
            {{
              buttonIndexString
                ? `${buttonIndexString}.${index + 1}`
                : index + 1
            }}</label
          >
          <button @click="deleteOption(option, index)">
            <i class="fas fa-trash-alt" />
          </button>
        </div>
        <div class="row options-row pt-3 pb-2 activate-select">
          <div class="d-flex flex-column col-lg-6">
            <div>
              <input
                type="text"
                v-model="option.label"
                :placeholder="placeholderOptions[index]"
                :class="{
                  input_error:
                    option.label == 0 && error.empty == 'empty_field',
                }"
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div>
              <select class="col-12" v-model="option.action">
                <option value="" disabled selected>Tipo de botón</option>
                <option
                  v-for="(botton, index) in optionButton"
                  :key="index"
                  :value="botton"
                >
                  {{ getButtonTypeName(botton) }}
                </option>
              </select>
            </div>
          </div>
          <!-- Mostrar el segundo select si 'answer' está seleccionado -->
          <div class="col-lg-12">
            <select
              v-model="option.selectedIntent"
              v-if="option.action === 'answer'"
            >
              <option value="" disabled selected>Seleccionar intent</option>
              <option
                :value="intent"
                v-for="intent in intents"
                :key="intent.id"
              >
                {{ intent.intent_ui_name }}
              </option>
            </select>
          </div>
          <!-- Mostrar el segundo menu si 'buttons' está seleccionado -->
          <div class="col-12 pb-2" v-if="option.action === 'buttons'">
            <label class="suboptionMenu">Opciones botón</label>
            <RecursiveInitialMenuOptions
              :buttonIndex="index + 1"
              :buttonIndexString="
                buttonIndexString
                  ? `${buttonIndexString}.${index + 1}`
                  : `${index + 1}`
              "
              :options="option.options || []"
              :level="level + 1"
            ></RecursiveInitialMenuOptions>
            <button
              @click="addSubOption(option, index)"
              v-if="
                (option.options && option.options.length < 5) || !option.options
              "
              class="btn btn-dash btn-dash-sm m-0 mt-2"
            >
              +
            </button>
          </div>
          <!-- Mostrar el input si 'url' está seleccionado -->
          <div class="col-lg-12">
            <input
              v-if="option.action === 'url'"
              type="text"
              class="col-lg-12"
              placeholder="Ingresar url"
              v-model="option.url"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RecursiveInitialMenuOptions from '@/components/RecursiveInitialMenuOptions.vue';

export default {
  name: 'RecursiveInitialMenuOptions',
  components: {
    RecursiveInitialMenuOptions,
  },
  props: {
    buttonIndex: {
      type: Number,
      default: 0,
    },
    buttonIndexString: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      intents: [],
      optionButton: ['answer', 'buttons', 'url'],
      placeholderOptions: [
        '🔩 Opciones',
        '🔥 Opciones',
        '🛠️ Opciones',
        '⚙️ Opciones',
        '🧰 Opciones',
        '🪛 Opciones',
        '📍 Opciones',
        '🧑‍💻 Opciones',
      ],
      error: {
        name: '',
        web: '',
        empty: '',
      },
    };
  },
  methods: {
    deteleParentOption(option, index) {
      // console.log('OPTIOOOON: ', option);
      // console.log('OPTION INDEEEEX: ', index);
      this.$swal({
        title: '¿Eliminar opción?',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Eliminar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (resp) => {
        if (resp.isConfirmed) {
          if (option.value.input.text && option.value.input.text !== '') {
            await this.$store.dispatch(
              'dtClientNodesModule/deleteButtonOption',
              {
                bot_id: this.bot_id,
                node_name: option.value.input.text,
              },
            );
          }
          this.$swal({
            title: 'Opción eliminada',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          });
          this.options.splice(index, 1);
        }
      });
    },
    deleteOption(option, index) {
      console.log('index -----> :', index);
      console.log('EL NIVEL: ', this.level);
      if (this.level === 0) {
        // remove level 0 option
        return this.deteleParentOption(option, index);
      }
      this.$swal({
        title: '¿Eliminar sub opción?',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Eliminar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (resp) => {
        if (resp.isConfirmed && index == 0) {
          await this.$store.dispatch('dtClientNodesModule/resetWelcomeButton', {
            bot_id: this.bot_id,
            option,
          });
          this.options.splice(index, 1);
        }
        if (resp.isConfirmed && index > 0) {
          await this.$store.dispatch('dtClientNodesModule/deleteSubOption', {
            bot_id: this.bot_id,
            option,
            suboption: this.options[index],
          });
          this.options.splice(index, 1);
        }
      });
    },
    getButtonTypeName(type) {
      switch (type) {
        case 'answer':
          return 'Respuesta';
        case 'buttons':
          return 'Botones';
        case 'url':
          return 'URL';
        default:
          return 'Tipo de botón';
      }
    },
    generateLevelString(parentLevel, index) {
      return parentLevel
        ? `${this.buttonIndexString}.${index + 1}`
        : `${index + 1}`;
    },
    addSubOption(option, index) {
      if (!this.options[index].options) {
        this.$set(this.options[index], 'options', []);
      }
      const newOption = {
        label: '',
        action: '',
        value: {
          input: {
            text: '',
          },
        },
        options: [],
      };
      this.$set(
        this.options[index].options,
        this.options[index].options.length,
        newOption,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.chatbot_settings {
  // padding: 6rem 0;

  .preview_box {
    width: 100%;
    position: relative;

    .fa-eye-slash,
    .fa-eye {
      position: absolute;
      color: #767676;
      font-size: 1rem;
      opacity: 0;
      top: 15px;
      right: 15px;
      // transition: .8s;
    }
    .text-preview {
      width: 100%;
      padding: 0.75rem;
      background-color: #f2f2f2;
      border-radius: 8px 0 0 8px;
      cursor: pointer;

      &:hover ~ .fa-eye-slash {
        opacity: 1 !important;
      }
      &:hover ~ .fa-eye {
        opacity: 1 !important;
      }
    }
  }
  .delete_box {
    padding: 0.75rem;
    width: 2.5rem;
    height: 45px;
    align-items: center;
    justify-content: center;
    border-radius: 0 8px 8px 0;
    border: 1px solid #e8e8e8;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      box-shadow: none;
    }
    .fa-trash-alt {
      color: #767676;
      margin-bottom: 0;
      transition: 0.5s;
    }
  }
  .etiqueta {
    margin-bottom: 0;
  }
  .no-row {
    @media (max-width: 1279px) {
      display: block;
    }
  }
  .col-lg-5,
  .col-lg-6 {
    @media (max-width: 1279px) {
      justify-content: center;
      margin: auto;
      max-width: 100%;
    }
    @media (max-width: 480px) {
      max-width: 100%;
    }
  }
  .card_box {
    padding: 0 0 0 3rem;

    @media (max-width: 480px) {
      padding: 0 0 1rem;
    }
  }
  .temas {
    margin-top: 2rem;

    &__item {
      .color_box {
        display: flex;
        align-items: center;
        margin: 1rem 0;

        input {
          width: 50px;
          height: 54px;
          padding: 0;
          margin: auto 0;
          border: none;
          background: transparent;
          cursor: pointer;

          &[type='color'] {
            margin-right: 0.5rem;

            &::-moz-color-swatch {
              border-radius: 5px;
            }
            &::-webkit-color-swatch-wrapper {
              border-radius: 5px;
              border-style: none;
            }
            // edita la forma del input color
            &::-webkit-color-swatch {
              border-radius: 5rem;
              border: 1px solid #cdcdcd;
            }
          }
        }
      }
    }
  }
  .check__content {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bajada {
      color: #767676;
      font-weight: 400;
    }
  }
  .desktop_icon {
    width: 1.25rem;
    margin-left: 0.5rem;
  }
  .mobile_icon {
    width: 0.9rem;
    margin-left: 0.5rem;
  }
  select {
    width: 100%;
  }
  .checks_box {
    justify-content: space-between;
    display: flex;
    margin: auto;
  }
  .btn-base {
    margin: 0;
    padding: 0.5rem;
    width: 25%;

    @media (max-width: 1600px) {
      width: 40%;
    }
    @media (max-width: 1279px) {
      padding: 1rem 0;
      margin-bottom: 1.5rem;
    }
  }
  .card {
    padding: 1.5rem;
    border-color: #cdcdcd;

    &-chat {
      top: 0;
    }

    .check_time {
      color: #767676;
    }
  }
  .info_box {
    @media (max-width: 1600px) {
      margin: 1.25rem auto;
    }
  }
  .two_box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;

    @media (max-width: 1600px) {
      display: block;
    }
  }
}
.cart-label-top {
  border: 1px solid #cdcdcd;
  border-bottom: 0;
  background-color: #f2f2f2;
  border-radius: 1rem 1rem 0 0;
}
.label-cart {
  padding: 10px 0 0 15px;
}
.options-row {
  border: 1px solid #cdcdcd;
  border-radius: 0 0 1rem 1rem;
}

.delete-input {
  display: flex;
  justify-content: space-between;
}

.delete-input-option button {
  border: none;
  width: 50px;
  margin-bottom: 0rem;
  border-radius: 0 1rem 0 0;
  border-left: 1px solid #cdcdcd;
}
.suboptionMenu {
  margin-left: 3px;
}
</style>
