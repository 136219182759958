<template>
  <FadeTransition :duration="200">
    <section class="chatbot_settings">
      <div class="container mt-5">
        <div class="row no-row justify-content-center">
          <div class="col-lg-6 mb-5">
            <div class="card_box">
              <h2 class="mb-4">
                {{ 'Menú de ' + selected_bot.bot_name }}
              </h2>
              <validationchat />
              <validationbot />
              <div>
                <label>Mensaje de bienvenida</label>
                <p>
                  Considera que los mensajes de bienvenida se suman, es decir,
                  aparecerán todos al inicio de la conversación
                </p>
                <div
                  v-for="(message, index) in welcomeVariants"
                  :key="message.id"
                  class="delete-input"
                >
                  <input
                    type="text"
                    :class="{
                      input_error:
                        message.text == 0 && error.empty == 'empty_field',
                    }"
                    v-model="message.text"
                    :placeholder="placeholderMessage[index]"
                  />
                  <button
                    v-if="welcomeVariants && welcomeVariants.length > 1"
                    @click="deleteWelcomeVariation(index)"
                  >
                    <i class="fas fa-trash-alt" />
                  </button>
                </div>
                <button
                  @click="addWelcomeVariant()"
                  class="btn btn-dash btn-dash-sm m-0 mt-2"
                  v-if="welcomeVariants.length < 3"
                >
                  +
                </button>
                <label class="mt-5">Opciones del menú</label>
                <SpinnerLoading v-if="loading" />
                <FadeTransition :duration="200" v-else>
                  <div>
                    <div>
                      <RecursiveInitialMenuOptions
                        :options="menuOptions"
                      ></RecursiveInitialMenuOptions>
                    </div>
                    <button
                      @click="addOptions()"
                      v-if="menuOptions.length < 6"
                      class="btn btn-dash btn-dash-sm m-0 mt-2"
                    >
                      +
                    </button>
                    <div
                      class="d-flex justify-content-between align-items-center my-5"
                    >
                      <button
                        class="btn btn-base"
                        @click.prevent="saveInitialMenu"
                        :disabled="loadingMenu"
                      >
                        <template v-if="loadingMenu">
                          <i class="fas fa-spinner fa-spin"></i> Guardando...
                        </template>
                        <template class="btn-act" v-else>Guardar</template>
                      </button>
                    </div>
                  </div>
                </FadeTransition>
              </div>
            </div>
          </div>
          <!-- Columna preview -->
          <div class="col-lg-5 p-0">
            <div class="card card-chat pt-0">
              <div class="card-body">
                {{ chatbot.trama_url }}
                <Chatbot
                  :chatbot__trama="chatbot.trama_url"
                  :chatbot__logo="chatbot.bot_logo_url"
                  :chatbot__nombre="chatbot.bot_name"
                  :chatbot__color__principal="chatbot.bot_title_bar_bg_color"
                  :chatbot__color__secundario="chatbot.bot_title_color"
                  :chatbot__bot__color__fondo="chatbot.bot_box_bg_color"
                  :chatbot__bot__color__letra="chatbot.bot_box_font_color"
                  :chatbot__user__color__fondo="chatbot.user_box_bg_color"
                  :chatbot__user__color__letra="chatbot.user_box_font_color"
                  :accessibility="chatbot.accessibility"
                  :bubble="chatbot.img_bubble"
                  :chatbot_menu="true"
                  :welcomes="welcomeVariants"
                  :options="menuOptions"
                  :buttons="submenuOptions"
                  :placeholderMessage="placeholderMessage"
                  :placeholderOptions="placeholderOptions"
                >
                </Chatbot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </FadeTransition>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import validationchat from './components/validationchat.vue';
import validationbot from './components/validationbot.vue';
import Chatbot from '@/components/Chatbot.vue';
import dashboard_api from '@/dashboard_api.js';
import { FadeTransition } from 'vue2-transitions';
import RecursiveInitialMenuOptions from '@/components/RecursiveInitialMenuOptions.vue';

export default {
  components: {
    validationchat,
    validationbot,
    FadeTransition,
    Chatbot,
    RecursiveInitialMenuOptions,
  },
  data() {
    return {
      loadingMenu: false,
      loading: false,
      chatbot: {
        id: '',
        name: '',
        bot_logo_url: '',
        trama_url: '',
        bot_title: '',
        user_box_bg_color: '#ffffff',
        user_box_font_color: '#525252',
        bot_box_bg_color: '#D8EEFF',
        bot_box_font_color: '#ffffff',
        bot_title_bar_bg_color: '#333',
        bot_title_color: '#ffffff',
        bot_name: '',
        option_bg_color: '#ffffff',
        option_font_color: '#525252',
        web: '',
        rolagente: [],
        template: '',
        type_bot: false,
        notification: false,
        message_end: '',
        accessibility: false,
        img_bubble: false,
        menu: false,
        desktop: false,
        mobile: false,
        init_conver: true,
        init_conver_mobile: true,
      },
      welcomeVariants: [
        {
          text: '',
        },
      ],
      menuOptions: [
        {
          label: '',
          selectedOption: '',
          value: {
            input: {
              text: '',
            },
          },
          options: [],
        },
      ],
      submenuOptions: [],
      error: {
        name: '',
        web: '',
        empty: '',
      },
      placeholderMessage: [
        'Bienvenido, estoy en línea, ¿Cómo puedo ayudarte?',
        '¡Hola!, estoy en línea, ¿En qué te puedo ayuda?',
        '¡Bienvenido!, estoy en línea, ¿En qué necesitas ayuda?',
      ],
      suboptionButton: ['answer', 'url'],
      optionButton: ['answer', 'buttons', 'url'],
      placeholderOptions: [
        '🔩 Opciones',
        '🔥 Opciones',
        '🛠️ Opciones',
        '⚙️ Opciones',
        '🧰 Opciones',
        '🪛 Opciones',
        '📍 Opciones',
        '🧑‍💻 Opciones',
      ],
    };
  },
  mounted() {
    this.initializeInitialMenu();
  },
  computed: {
    ...mapState([
      'user',
      'selected_bot',
      'ownerBots',
      'plan_name',
      'skill_template',
    ]),
    bot_id() {
      return 2104;
    },
  },
  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.getChatbotEdit();
      },
    },
  },
  methods: {
    ...mapMutations(['editSelectedBot', 'selected_bot', 'setTemplateValue']),

    async initializeInitialMenu() {
      this.loading = true;
      console.log('TEMPLATE: ', this.skill_template);
      // getting intents
      this.intents = await this.$store.dispatch(
        'dtClientIntentsModule/listByBotId',
        this.bot_id,
      );
      console.log('THIS INTENTS-----> ', this.intents);
      // filter only intents which start with FAQ. and CUSTOM.
      if (this.intents && this.intents.length) {
        this.intents = this.intents.filter(
          (intent) =>
            intent.intent_name.startsWith('INTERNA.') ||
            intent.intent_name.startsWith('FAQ.') ||
            intent.intent_name.startsWith('CUSTOM.'),
        );
      }
      // get welcome texts
      const WELCOME_INTENT_NAME = 'INICIO'; // forever, dont change
      const WELCOME_INTENT_BUTTONS_NAME = 'MENÚ'; // forever, dont change
      const TEMPLATE_NAME = this.skill_template.value;
      console.log('🚀 Aqui *** -> skill_template:', this.skill_template);
      const welcomeNode = await this.$store.dispatch(
        'dtClientNodesModule/listByIntentName',
        {
          intentName: WELCOME_INTENT_NAME,
          botId: this.bot_id,
          template: this.skill_template.value,
        },
      );
      console.log('WELCOME NODE -->', welcomeNode);
      if (welcomeNode.output) {
        this.welcomeVariants = welcomeNode.output.generic[0].values;
        // get buttons
        const welcomeNodeButtons = await this.$store.dispatch(
          'dtClientNodesModule/listByIntentName',
          {
            intentName: WELCOME_INTENT_BUTTONS_NAME,
            botId: this.bot_id,
            template: this.skill_template.value,
          },
        );
        let menuOptions = welcomeNodeButtons.output.generic[0].options;
        // adding submenuOptions
        menuOptions = menuOptions.map((el) => ({
          ...el,
          options: [],
        }));
        // get data for each button
        for (let option of menuOptions) {
          option = await this.getOptionData(option);
        }
        this.menuOptions = menuOptions;
        this.menuOptions = this.menuOptions.slice();
        this.loading = false;
        // this.menuOptions.forEach(async (option, index) => {
        //   this.menuOptions[index] = await this.getOptionData(option);
        // });
      }
    },

    async getOptionData(option) {
      const TEMPLATE_NAME = this.skill_template;
      console.log('🚀 Aqui *** -> template_name:', TEMPLATE_NAME);
      console.log('🚀 Aqui *** -> option:', option);
      const textValue = option.value.input.text;
      if (textValue.startsWith('boton_')) {
        // get node by intent name
        const node = await this.$store.dispatch(
          'dtClientNodesModule/listByIntentName',
          {
            intentName: textValue,
            botId: this.bot_id,
            template: this.skill_template.value,
          },
        );
        if (node) {
          console.log('🚀 Aqui *** -> node.output:', node.output);
          console.log('🚀 Aqui *** -> textValue:', textValue);
          // check type
          option['action'] = node.next_step;
          console.log('🚀 Aqui *** -> node.next_step:', node.next_step);
          // testing other workaround //
          // switch (option['action']) {
          //  case 'answer': {
          //    break;
          //  }
          //  case 'buttons':
          //    break;
          //  case 'url':
          //    break;
          //  default:
          //    break;
          // }

          // in case of answer, get node associated
          if (option['action'] === 'answer') {
            let intentAssociated = await this.$store.dispatch(
              'dtClientNodesModule/getIntentAssociated',
              {
                nodeId: node.jump_to,
                bot_id: this.botId,
              },
            );
            if (intentAssociated.status === true) {
              option['selectedIntent'] = this.intents.find(
                (el) => el.intent_ui_name === intentAssociated.intent,
              );
            } else {
              option['selectedIntent'] = '';
            }
          }
          if (option['action'] === 'buttons') {
            console.log('🚀 Aqui *** -> suboption:', node);
            let subOptions = node.output.generic.find(
              (el) => el.response_type === 'option',
            );
            subOptions = subOptions ? subOptions.options : [];
            if (subOptions) {
              // get data for each button
              // subOptions.forEach(async (option, index) => {
              //   subOptions[index] = await this.getOptionData(option);
              // });
              for (let subOption of subOptions) {
                subOption = await this.getOptionData(subOption);
              }
            }
            // push every suboption
            option['options'] = subOptions;
          }
        } else {
          console.log('Node not found: ', textValue);
        }
      } else {
        // case for url
        option['action'] = 'url';
        option['url'] = textValue;
      }
      return option;
    },
    addOptions() {
      this.menuOptions.push({
        label: '',
        action: '',
        value: {
          input: {
            text: '',
          },
        },
        options: [],
      });
    },
    addWelcomeVariant() {
      this.welcomeVariants.push({
        text: '',
      });
    },
    deleteWelcomeVariation(index) {
      this.$swal({
        title: '¿Eliminar variación?',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Eliminar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (resp) => {
        if (resp.isConfirmed) {
          this.welcomeVariants.splice(index, 1);
        }
      });
    },
    async saveInitialMenu() {
      this.loadingMenu = true; // mostrar indicador de carga
      await Promise.all([
        this.$store.dispatch('dtClientIntentsModule/intentComplete', {
          template: this.skill_template.value,
          bot_id: this.bot_id,
        }),
        this.$store.dispatch('dtClientNodesModule/nodeComplete', {
          template: this.skill_template.value,
          bot_id: this.bot_id,
        }),
        this.$store.dispatch('dtClientEntitiesModule/entityCopyTemplate', {
          template: this.skill_template.value,
          bot_id: this.bot_id,
        }),
      ]);
      // saving texts
      await this.$store.dispatch('dtClientNodesModule/updateWelcomeTexts', {
        answers: this.welcomeVariants.map((el) => el.text),
        bot_id: this.bot_id,
        template: this.skill_template.value,
        buttons: this.menuOptions.map((el) => ({
          text: el.label,
          type: el.action,
          url: el.url ? el.url : null,
        })),
      });
      const formattedOptions = await this.formatInitialMenuOptions(
        this.menuOptions,
      );
      let result = await this.$store.dispatch(
        'dtClientNodesModule/updateWelcomeButtons',
        {
          template: this.skill_template.value,
          buttons: formattedOptions,
          bot_id: this.bot_id,
        },
      );
      console.log('---------------> RESULT: ', result);
      if (result.msg === 'error-botones') {
        this.loadingMenu = false;
        return this.$swal({
          icon: 'error',
          title: 'Existen botones duplicados',
          text: 'Los botones no pueden tener el mismo texto',
          timer: 2500,
          showConfirmButton: false,
        });
      }
      this.loadingMenu = false; // ocultar indicador de carga
      this.$swal({
        icon: 'success',
        title: 'Menú Inicial Actualizado',
        timer: 1000,
        showConfirmButton: false,
      });
      this.initializeInitialMenu();
    },

    async getChatbotEdit() {
      let self = this;
      self.chatbot = (
        await dashboard_api.get(`/bot/showedit/${self.bot_id}`)
      ).data;

      const businessType = (
        await dashboard_api.get(`/get_bot_info/${self.chatbot.id}`)
      ).data.business_type;
      self.custom = businessType.show;
      // inicializando horarios comerciales
      if (self.showSchedule) {
        dashboard_api.get(`schedule/list/${self.chatbot.id}`).then((res) => {
          for (const schedule of res.data) {
            const formatted = self.formatSchedule(schedule);
            self.addSchedule(
              schedule.id,
              formatted.days,
              formatted.hourFrom,
              formatted.hourTo,
            );
          }
        });
      }
    },
    async formatInitialMenuOptions(options) {
      const buttonButtons = options.filter((el) => el.action === 'buttons');
      const promisesButtonButtons = buttonButtons.map(async (buttonButton) => {
        buttonButton.options = await this.formatInitialMenuOptions(
          buttonButton.options,
        );
        return buttonButton;
      });

      await Promise.all(promisesButtonButtons);
      const answerButtons = options.filter((el) => el.action === 'answer');
      // get nodes by intent name for answerButtons
      const promises = await Promise.all(
        answerButtons.map((button, index) =>
          this.$store.dispatch('dtClientNodesModule/listByIntentName', {
            intentName: button.selectedIntent.intent_name,
            botId: this.bot_id,
            template: this.skill_template.value,
          }),
        ),
      );
      // asign each promise to each button
      answerButtons.forEach((button, index) => {
        if (promises[index].id) {
          button.jump_to = promises[index].id;
        }
      });
      // last format to buttons
      options.forEach((option, index) => {
        if (option.action === 'answer') {
          options[index] = {
            text: option.label,
            action: option.action,
            jump_to: option.jump_to,
          };
        }
        if (option.action === 'buttons') {
          options[index] = {
            text: option.label,
            action: option.action,
            options: option.options,
          };
        }
        if (option.action === 'url') {
          options[index] = {
            text: option.label,
            action: option.action,
            url: option.url,
          };
        }
      });

      return options;
    },
  },
};
</script>

<style lang="scss" scoped>
.chatbot_settings {
  // padding: 6rem 0;

  .preview_box {
    width: 100%;
    position: relative;

    .fa-eye-slash,
    .fa-eye {
      position: absolute;
      color: #767676;
      font-size: 1rem;
      opacity: 0;
      top: 15px;
      right: 15px;
      // transition: .8s;
    }
    .text-preview {
      width: 100%;
      padding: 0.75rem;
      background-color: #f2f2f2;
      border-radius: 8px 0 0 8px;
      cursor: pointer;

      &:hover ~ .fa-eye-slash {
        opacity: 1 !important;
      }
      &:hover ~ .fa-eye {
        opacity: 1 !important;
      }
    }
  }
  .delete_box {
    padding: 0.75rem;
    width: 2.5rem;
    height: 45px;
    align-items: center;
    justify-content: center;
    border-radius: 0 8px 8px 0;
    border: 1px solid #e8e8e8;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      box-shadow: none;
    }
    .fa-trash-alt {
      color: #767676;
      margin-bottom: 0;
      transition: 0.5s;
    }
  }
  .etiqueta {
    margin-bottom: 0;
  }
  .no-row {
    @media (max-width: 1279px) {
      display: block;
    }
  }
  .col-lg-5,
  .col-lg-6 {
    @media (max-width: 1279px) {
      justify-content: center;
      margin: auto;
      max-width: 100%;
    }
    @media (max-width: 480px) {
      max-width: 100%;
    }
  }
  .card_box {
    padding: 0 0 0 3rem;

    @media (max-width: 480px) {
      padding: 0 0 1rem;
    }
  }
  .temas {
    margin-top: 2rem;

    &__item {
      .color_box {
        display: flex;
        align-items: center;
        margin: 1rem 0;

        input {
          width: 50px;
          height: 54px;
          padding: 0;
          margin: auto 0;
          border: none;
          background: transparent;
          cursor: pointer;

          &[type='color'] {
            margin-right: 0.5rem;

            &::-moz-color-swatch {
              border-radius: 5px;
            }
            &::-webkit-color-swatch-wrapper {
              border-radius: 5px;
              border-style: none;
            }
            // edita la forma del input color
            &::-webkit-color-swatch {
              border-radius: 5rem;
              border: 1px solid #cdcdcd;
            }
          }
        }
      }
    }
  }
  .check__content {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bajada {
      color: #767676;
      font-weight: 400;
    }
  }
  .desktop_icon {
    width: 1.25rem;
    margin-left: 0.5rem;
  }
  .mobile_icon {
    width: 0.9rem;
    margin-left: 0.5rem;
  }
  select {
    width: 100%;
  }
  .checks_box {
    justify-content: space-between;
    display: flex;
    margin: auto;
  }
  .btn-base {
    margin: 0;
    padding: 0.5rem;
    width: 25%;

    @media (max-width: 1600px) {
      width: 40%;
    }
    @media (max-width: 1279px) {
      padding: 1rem 0;
      margin-bottom: 1.5rem;
    }
  }
  .card {
    padding: 1.5rem;
    border-color: #cdcdcd;

    &-chat {
      top: 0;
    }

    .check_time {
      color: #767676;
    }
  }
  .info_box {
    @media (max-width: 1600px) {
      margin: 1.25rem auto;
    }
  }
  .two_box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;

    @media (max-width: 1600px) {
      display: block;
    }
  }
}
.cart-label-top {
  border: 1px solid #cdcdcd;
  border-bottom: 0;
  background-color: #f2f2f2;
  border-radius: 1rem 1rem 0 0;
}
.label-cart {
  padding: 10px 0 0 15px;
}
.options-row {
  border: 1px solid #cdcdcd;
  border-radius: 0 0 1rem 1rem;
}

.delete-input {
  display: flex;
  justify-content: space-between;
}

.delete-input-option button {
  border: none;
  width: 50px;
  margin-bottom: 0rem;
  border-radius: 0 1rem 0 0;
  border-left: 1px solid #cdcdcd;
}
.suboptionMenu {
  margin-left: 3px;
}
</style>
