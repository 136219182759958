<template>
  <section class="perfil">
    <SpinnerLoading v-if="loading" />
    <template v-else>
      <div class="container mt-5">
        <div class="row no-row justify-content-center">
          <div class="col-lg-5 mb-5">
            <div class="card__box">
              <h2 class="mb-5">Configura tu cuenta</h2>
              <div>
                <label for="Nombre">Nombre</label>
                <input placeholder="Ingresa un nombre" v-model="model.name" />
                <label for="Email">Correo</label>
                <input placeholder="nombre@empresa.cl" v-model="model.email" />
                <label for="Teléfono">Teléfono</label>
                <input placeholder="9 1234 5678" v-model="model.phone" />
                <label for="password">Ingresa tu contraseña para guardar los cambios</label>
                <input
                  type="password"
                  placeholder="Ingresa tu contraseña"
                  required
                  v-model="model.password"
                />
              </div>
              <button @click="save" class="btn btn-base my-4" id="btn-input">Guardar</button>
            </div>
          </div>
          <div class="col-md-5">
            <label>Foto de perfil</label>
            <div class="align-items-center justify-content-center d-flex m-auto">
              <div class="imagen__perfil">
                <img 
                  :src="imagePreview || '/img/icons/dashboard/0-user-profile.svg'" 
                  alt="Foto de perfil" 
                />
              </div>
            </div>
            <input type="file" @change="processFile($event)" id="input-file" />
            <p class="text__warning">
              La imagen no puede superar los 300kb.
            </p>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import dashboard_api from "@/dashboard_api.js";
import dashboard_api_v2 from "@/dashboard_api_v2";

export default {
  name: "user-profile",

  data() {
    return {
      loading: false,
      model: {},
      fact: {},
      bussiness_id: "",
      error: "",
      imageAvatar: "",
      imagePreview: "",
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getUserInfo();
  },
  computed: {
    ...mapState(["logged", "user"])
  },
  methods: {
    ...mapMutations(["changeImage", "logout"]),

    async getUserInfo() {
      const self = this;

      self.model = (await dashboard_api.get(`/relief/user?user_id=${self.user.id}`)).data;
      self.imagePreview = self.model.user_avatar || self.model.image;
      console.log("model>", self.model);
      if (self.model.user_facturation) {
        self.fact = self.model.user_facturation;
        self.bussiness_id = self.model.user_facturation.id;
      }
      self.model.image == null
        ? (self.model.image = "/img/icons/dashboard/0-user-profile.svg")
        : "";
    },
    formDataBody() {
      const data = new FormData();
      data.append("password", this.model.password);
      data.append("client[name]", this.model.name);
      data.append("client[email]", this.model.email);
      data.append("client[phone]", this.model.phone.toString().includes(" ") ? this.model.phone.replace(/\s/g, "") : this.model.phone);
      data.append("client[image]", "");
      if (this.imageAvatar);
        data.append("client[user_avatar]", this.imageAvatar);

      return data;
    },

    save() {
      const self = this;

      self.$swal({
        icon: "info",
        title: "Guardando",
        showConfirmButton: false,
        allowOutsideClick: false
      });
      self.$swal.showLoading();

      // validacion para no romper la img de perfil cuando no se cargue
      let image = self.model.foto_url || self.model.image;
      const formData = self.formDataBody()
      dashboard_api_v2
        .put("client/update", formData)
        .then(result => {
          console.log(result);
          // console.log(result);
          if (result.data.status == 200) {
            self.$store.commit("changeImage", image);
            self.$store.commit("changeName", self.model.name);
            self.$swal({
              icon: "success",
              title: "Se han guardado los cambios"
            });
          } else if (result.data.status == 300) {
            this.$swal({
              title: result.data.title,
              text: result.data.msj,
              icon: "success",
              showConfirmButton: true,
              confirmButtonText: "Ok",
              allowOutsideClick: false
            }).then(r => {
              if (r.isConfirmed) {
                self.logout();
                self.$router.go(0);
              }
            });
          } else {
            self.$swal({
              icon: "error",
              title: result.data.msj
            });
          }
        })
        .catch(error => console.log("error > ", error));
    },

    processFile(event) {
      const self = this;

      const reader = new FileReader();
      self.imageAvatar = event.target.files[0]
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function () {
        console.log(reader.result);
        self.model.foto_url = reader.result;
        self.imagePreview = reader.result
        self.model.image = self.model.foto_url;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.perfil {
  width: 100%;

  .card__box {
    padding: 0 2rem;
  }
  input {
    &[type="file"]::-webkit-file-upload-button {
      align-items: center;
      background: #b2b2b2;
      color: #fff;
      border: none;
      border-radius: 3px;
      font-size: 12px;
    }
  }
  select {
    width: 100%;

    @media (max-width: 1024px) {
      margin: 0 auto;
    }
  }
  #btn-input {
    width: 120px;

    @media (max-width: 480px) {
      width: 100%;
    }
  }
  .imagen__perfil {
    width: 152px;
    height: 152px;
    flex-grow: inherit !important;
    margin-bottom: 15px;

    img {
      border: 1px solid #e8e8e8;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
</style>
