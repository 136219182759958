<template>
  <section class="registro" id="Comenzar">
    <div class="text-center mb-5">
      <p class="text_sm">
        Prueba Databot gratis por
        {{ platform_name == "shopify" ? 14 : 7 }} días.
      </p>
      <h2>
        Regístrate <span class="destacado">gratis</span> y crea tu chatbot en
        <span class="destacado">1</span> minuto.
      </h2>
      <BulletSteps :step="step" />
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-6 d-flex justify-content-center">
        <form role="form">
          <div class="row justify-content-between">
            <div class="col-lg-6 pl-0 pr-2">
              <span
                v-if="error == 'invalid_name'"
                class="d-block text_error mt-0"
                >Ingresa tu nombre</span
              >
              <label v-else>Nombre</label>
              <input
                :class="{'mb-3': true, input_error: error == 'invalid_name' }"
                placeholder="Ingresa tu nombre"
                v-model="model.firstName"
                required
              />
            </div>
            <div class="col-lg-6 pr-0 pl-2">
              <span
                v-if="error == 'invalid_lastname'"
                class="d-block text_error mt-0"
                >Ingresa tu apellido</span
              >
              <label v-else>Apellido</label>
              <input
                :class="{'mb-3': true, input_error: error == 'invalid_lastname' }"
                placeholder="Apellido"
                v-model="model.lastName"
                required
              />
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-lg-6 pl-0 pr-2">
              <span
                v-if="error == 'invalid_mail'"
                class="d-block text_error mt-0"
                >Ingresa tu correo</span
              >
              <label v-else>Correo</label>
              <input
                :class="{'mb-3': true, input_error: error == 'invalid_mail' }"
                type="email"
                placeholder="nombre@empresa.cl"
                v-model="model.email"
                required
              />
            </div>
            <div class="col-lg-6 pr-0 pl-2">
              <span
                v-if="error == 'invalid_url'"
                class="d-block text_error mt-0"
                >Verifica tu sitio web</span
              >
              <label v-else>URL Empresa</label>
              <input
                :class="{'mb-3': true, input_error: error == 'invalid_url' }"
                placeholder="www.nombre-empresa.cl"
                v-model="model.url"
                type="url"
                required
              />
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-lg-6 pl-0 pr-2">
              <label>Teléfono</label>
              <input
                class="mb-3"
                placeholder="+569 1234 5678"
                v-model="model.phone"
                required
              />
            </div>
            <div class="col-lg-6 pr-0 pl-2">
              <span
                v-if="error == 'invalid_pass'"
                class="d-block text_error mt-0"
                >Mínimo 6 caracteres</span
              >
              <label v-else>Contraseña</label>
              <input
                :class="{'mb-3': true, input_error: error == 'invalid_pass' }"
                placeholder="Ingresa contraseña"
                type="password"
                v-model="model.password"
                required
              />
            </div>
          </div>
          <!-- <label>Confirmar contraseña</label>-->
          <!-- <input
            class="mb-3"
            placeholder="Repite contraseña"
            type="password"
            id="pass-hidden"
            v-model="model.password_confirmation"
          />  -->
          <div class="text-center mt-4">
            <p>
              Al crear tu cuenta aceptas nuestros
              <span class="btn-text" @click="show_modal_terms = true"
                >Términos y condiciones</span
              >.
            </p>
          </div>
          <div class="text-center my-4">
            <button
              :disabled="loading"
              class="btn btn-base second my-2"
              @click.prevent="register"
            >
              Crear cuenta
            </button>
          </div>
        </form>
      </div>
    </div>
    <Modal :show="show_modal_terms" @close="show_modal_terms = false">
      <template v-slot:content @close="show_modal_terms = false">
        <TermsAndConditions />
      </template>
    </Modal>
  </section>
</template>
<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import dashboard_api from "@/dashboard_api.js";
import TermsAndConditions from "@/views/Auth/components/TermsAndConditions";
import BulletSteps from "@/views/Auth/components/BulletSteps";
import environment from "@/environment.js";
import chatbot_api from "@/chatbot_api.js";

export default {
  name: "FormRegistro",
  props: ["step", "type"],
  components: {
    TermsAndConditions,
    BulletSteps,
  },
  data() {
    return {
      changeRoute: this.$route,
      model: {
        name: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        url: "",
        origin_ecommerce: {
          url_origin: "",
          access_token: "",
          welcome_instructions: false,
        },
        password: "",
        terms_confirmation: "",
      },
      loading: false,
      error: "",
      show_modal_terms: false,
      mailchimp: {
        register: { tags: ["lead-cuenta-creada"] },
        register_jumpseller: { tags: ["usuario-jumpseller"] },
      },
      gtm: {
        register: {
          sendTo: "AW-622614762/bZFqCPeGpfABEOqx8agC",
        },
      },
      urlStatus: false,
      token: "",
      platform: "", // jumpseller, shopify, etc
      shop_domain: "", // dominio del comercio
    };
  },

  computed: {
    ...mapState(["user", "platform_name", "platform_shop", "platform_access"]),

    validEmail() {
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.model.email);
    },

    validUrl() {
      // let re = /w{3}\.[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}/;
      let re = /(w{3}\.)?[a-zA-Z0-9@:%._\-+~#=]{2,256}\.[a-z]{2,6}/;
      return re.test(this.model.url);
    },
  },

  methods: {
    ...mapMutations(["loginAction", "setRegisterProcess"]),

    async checkUrl() {
      const instance = axios.create({
        baseURL: environment.url_env_self,
      });
      this.urlStatus = (
        await instance.get(`/urls/check?url=${this.model.url}`)
      ).data;
    },

    closeTermsAndConditions(value) {
      this.show_modal_terms = value;
    },

    async register() {
      const self = this;

      const {
        name,
        firstName,
        lastName,
        email,
        phone,
        password,
        url,
        origin_ecommerce,
      } = self.model;

      if (self.platform_name === "shopify") {
        origin_ecommerce.url_origin = self.platform_shop;
        origin_ecommerce.access_token = self.platform_access;
        origin_ecommerce.welcome_instructions = true;
      }
      // Validaciones
      await self.checkUrl();

      if (!firstName) self.error = "invalid_name";
      else if (!lastName) self.error = "invalid_lastname";
      else if (!self.validEmail) self.error = "invalid_mail";
      else if (!self.validUrl || !self.urlStatus) self.error = "invalid_url";
      else if (!password || password.length < 6) self.error = "invalid_pass";
      // # Validaciones
      else {
        self.$swal({
          icon: "info",
          title: "Estamos creando su cuenta...",
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        self.$swal.showLoading();

        self.error = "";
        self.loading = true;
        self.phone2 = 1;

        // Creo usuario
        dashboard_api
          .post("/auth", {
            name: `${firstName} ${lastName}`,
            first_name: firstName,
            last_name: lastName,
            email,
            phone: phone.toString().includes(" ") ? phone.replace(/\s/g, "") : phone,
            origin_ecommerce,
            url,
            password,
            password_confirmation: password,
          })
          .then((result) => {
            // Enviado campo url para guardar en chatbot web
            self.$emit("sendChatbotWeb", url);

            const result_data = result;
            self.newUser = result.data;
            if (result.status == 201) {
              // integracion MAILCHIMP, envio correo
              if (self.mailchimp[self.type]) {
                // console.log("mailchimp");
                axios.post(environment.socket_url + "/mailchimp/lists/5eb3f069ce/members", {
                  business: "Databot",
                  payload: {
                    email_address: email,
                    full_name: `${firstName} ${lastName}`,
                    status: "subscribed",
                    source: "Integration",
                    tags: self.mailchimp[self.type].tags,
                    merge_fields: {
                      FNAME: `${firstName} ${lastName}`,
                      ADDRESS: "",
                      PHONE: phone
                    }
                  }
                });
              }

              // agrego etiqueta gtm
              if (self.gtm[self.type]) {
                gtag("event", "conversion", {
                  send_to: self.gtm[self.type].sendTo,
                });
              }

              // Logeo al usuario
              // TODO Buscar la forma de devolver al usuario logueado al crear la cuenta y nos ahorramos este paso
              dashboard_api
                .post("/auth/sign_in", {
                  email,
                  password,
                })
                .then((response) => {
                  self.$store.commit("authModule/credentialsStorage", {
                    ...response.headers,
                  });
                  self.loginAction({
                    user: result_data.data,
                    token: response.headers["access-token"],
                    botIds: [],
                    url,
                  });

                  dashboard_api
                    .post("/flow_requests/create_customer", {
                      flow_request: {
                        name: `${firstName} ${lastName}`,
                        email,
                        externalId: result_data.data.id,
                      },
                    })
                    .then((flowResponse) => {
                      console.log('flowResponse >', flowResponse);

                      self.$swal({
                        icon: "success",
                        title: "¡Cuenta creada!",
                        timer: 1000,
                        showConfirmButton: false,
                      });

                      self.setRegisterProcess({
                        user: result_data.data.id,
                        step: 2,
                        customerId: flowResponse.data.customerId,
                      });
                    })
                    .catch((error) => console.log(error)); // reject(error));

                  dashboard_api
                    .post("/mails/landing", {
                      name: `${firstName} ${lastName}`,
                      email,
                      phone,
                      mensaje: url,
                    })
                    .then((responseemail) => {
                      chatbot_api
                        .get("/getuser", {
                          params: {
                            email,
                          },
                        })
                        .then(async (getdatauser) => {
                          if (getdatauser.data.error) {
                            chatbot_api
                              .post("/sendlead", {
                                email,
                                name: `${firstName} ${lastName}`,
                                firstName,
                                lastName,
                                web: url,
                                phone,
                              })
                              .then(async (response) => {
                                // realiza la busqueda de la empresa
                                const getcompany = await self.getcompany();

                                if (getcompany.data.response.results != "") {
                                  // Agrega el lead a la empresa
                                  await self.addleadcompany(
                                    response,
                                    getcompany
                                  );

                                  await self.newbusiness(getcompany, response);
                                } else {
                                  // crea la empresa
                                  await self.createcompany();

                                  // realiza la busqueda de la empresa
                                  const getcompanycreate =
                                    await self.getcompany();

                                  if (
                                    getcompanycreate.data.response.results
                                      .length == 1
                                  ) {
                                    await self.addleadcompany(
                                      response,
                                      getcompanycreate
                                    );

                                    await self.newbusiness(
                                      getcompanycreate,
                                      response
                                    );
                                  } else {
                                    const companyresults =
                                      getcompanycreate.data.response.results;

                                    companyresults.forEach(async (element) => {
                                      if (element.properties.name) {
                                        if (
                                          element.properties.name.value == url
                                        ) {
                                          // eliminar compañia
                                          await self.deletebusiness(element);

                                          const getcompanycreate2 =
                                            await self.getcompany();

                                          await self.newbusiness(
                                            getcompanycreate2,
                                            response
                                          );
                                        }
                                      } else {
                                      }
                                      // end foreach
                                    });
                                  }
                                }
                              })
                              .catch((error) =>
                                console.log("errorrrrrrrrr =>", error)
                              );
                          } else {
                            const getcompany = await self.getcompany();

                            if (getcompany.data.response.results != "") {
                              // realiza el agregar
                              await self.addleadcompany(
                                getdatauser,
                                getcompany
                              );

                              await self.newbusiness(getcompany, getdatauser);
                            } else {
                              // crea la empresa
                              await self.createcompany();

                              const getcompanycreate = await self.getcompany();

                              if (
                                getcompanycreate.data.response.results.length ==
                                1
                              ) {
                                // realiza el agregar
                                await self.addleadcompany(
                                  getdatauser,
                                  getcompanycreate
                                );
                                // crea el negocio
                                await self.newbusiness(
                                  getcompanycreate,
                                  getdatauser
                                );
                              } else {
                                const companyresults =
                                  getcompanycreate.data.response.results;
                                companyresults.forEach(async (element) => {
                                  if (element.properties.name) {
                                    if (element.properties.name.value == url) {
                                      await self.deletebusiness(element);

                                      // realiza la busqueda de la empresa
                                      const getcompanycreate2 =
                                        await self.getcompany();

                                      // crea el negocio
                                      await self.newbusiness(
                                        getcompanycreate2,
                                        getdatauser
                                      );
                                    }
                                  }
                                });
                              }
                            }
                          }
                        });
                    })
                    .catch((error) => console.log("error =>", error));

                  // TODOMovido al then de flow -> Fix al demorar en cargar, estando en el paso 3 se devuelve al paso 2
                  // self.$swal({
                  //   icon: "success",
                  //   title: "¡Cuenta creada!",
                  //   timer: 1000,
                  //   showConfirmButton: false,
                  // });

                  self.$router.push({
                    query: {
                      name: "primer-chatbot",
                      token_access: self.platform_access,
                      shop_domain: self.platform_shop,
                    },
                  });
                  self.$emit("nextStep");
                });
            }
          })
          .catch((err) => {
            self.loading = false;
            console.log("err -> ", err);
            self.$swal({
              icon: "error",
              title: "Verifica el correo ingresado",
            });
          });
      }
    },
    createcompany() {
      return new Promise((resolve, reject) => {
        chatbot_api
          .post("/createcompany", {
            name: this.model.url,
          })
          .then(async (company) => {
            resolve(company);
          })
          .catch((error) => reject(error));
      });
    },

    getcompany() {
      return new Promise((resolve, reject) => {
        chatbot_api
          .get("/getcompany", {
            params: {
              domain: this.model.url,
            },
          })
          .then((getcompany) => {
            resolve(getcompany);
          })
          .catch((error) => reject(error));
      });
    },

    deletebusiness(element) {
      chatbot_api
        .delete("/deletebusiness", {
          params: {
            id: element.companyId,
          },
        })
        .then(async (responsse) => {});
    },

    addleadcompany(response, getcompany) {
      return new Promise((resolve, reject) => {
        chatbot_api
          .put("/addleadcompany", {
            client: response.data.response.vid,
            company: getcompany.data.response.results[0].companyId,
          })
          .then((addcompany) => {
            resolve(addcompany);
          })
          .catch((error) => reject(error));
      });
    },

    newbusiness(getcompany, response) {
      return new Promise((resolve, reject) => {
        chatbot_api
          .post("/newbusiness", {
            companiid: getcompany.data.response.results[0].companyId,
            clientid: response.data.response.vid,
            domain: this.model.url,
            from: this.$route.name,
          })
          .then((business) => {
            if (
              business.data &&
              business.data.response &&
              business.data.response.dealId
            ) {
              // this.setDeal(business.data.response.dealId);
              dashboard_api.put("/relief/useredit", {
                user_id: this.newUser.id,
                hubspot_deal_id: business.data.response.dealId,
                hubspot_company_id:
                  getcompany.data.response.results[0].companyId,
                hubspot_client_id: response.data.response.vid,
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.registro {
  width: 100%;

  @media (max-width: 480px) {
    margin: 2rem auto;
  }
  h2 {
    font-size: 24px;
  }
  .btn-base {
    padding: 0.75rem 2rem;
  }
  .btn-text {
    font-size: 14px;
  }
  @media (max-width: 480px) {
    input {
      height: 50px;
      font-size: 1.12rem;
    }
  }
  .pr-2,
  .pl-2 {
    @media (max-width: 960px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
</style>
